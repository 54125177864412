import VueRouter from 'vue-router';
import Vue from 'vue';
import App from './App.vue';
import config from './config';
import router from './router';
import store from './store';
import VueTheMask from 'vue-the-mask';
import SlideUpDown from 'vue-slide-up-down';
import {VueMasonryPlugin} from 'vue-masonry';
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'

Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)

import Default from '@/layouts/Default.vue';
import Promo from '@/layouts/Promo.vue';

Vue.config.productionTip = false;

Vue.component('default-layout', Default);
Vue.component('promo-layout', Promo);
Vue.component('slide-up-down', SlideUpDown);

Vue.prototype.$config = config;

Vue.use(VueRouter);
Vue.use(VueTheMask);
Vue.use(VueMasonryPlugin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
