<template>
<div class="plans-list">
  <div class="plan-item" v-for="item in items" :key="'plan_' + item.id">
    <router-link :to="item.url">
      <span>{{ item.name }}</span>
    </router-link>
  </div>
</div>
</template>

<script>
export default {
  name: 'List',
  props: {
    items: {type: Array, require: true}
  }
}
</script>

<style scoped lang="scss">
.plans-list {
  margin-bottom: 30px;
  display: inline-block;

  @media screen and (min-width: 621px) {
    margin-bottom: 0;
  }

  .plan-item {
    margin-bottom: 8px;

    a {
      display: block;
      font-family: 'Suisse';
      font-style: normal;
      font-weight: 400;
      font-size: 21px;
      line-height: 43px;
      letter-spacing: 0.004em;
      text-transform: uppercase;
      color: #000;
      border-bottom: 2px solid #000;
      background-image: url('/images/arrow-right-redesign.svg');
      background-repeat: no-repeat;
      background-position: center right;
      padding-right: 55px;
      background-size: 43px;
      max-width: calc(100vw - 30px);

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;

        @media screen and (min-width: 621px) {
          max-width: 500px;
        }
      }

      @media screen and (min-width: 621px) {
        font-size: 36px;
        line-height: 53px;
        background-size: auto;
        padding-right: 75px;
      }
    }
  }
}
</style>