<template>
  <div class="feedback-page centered-form">
    <div class="centered-form-container">
      <div>
        <div class="feedback-form-container">
          <div class="section-title _phone">Обратная связь</div>
          <div class="section-title _desktop">Написать в службу<br> поддержки</div>
          <br><br>
          <form action="" class="feedback-form" autocomplete="off">
            <div class="input-group">
              <input v-model="name" type="text" placeholder="ваше имя" required :class="{error: error.name}">
            </div>
            <div class="input-group">
              <input v-model="email" type="text" placeholder="ваш email" required :class="{error: error.email}">
            </div>
            <div class="input-group">
              <textarea v-model="text" placeholder="какой у вас вопрос?" required :class="{error: error.text}"/>
            </div>

              <a href="#" style="margin-bottom: 5px;" class="btn" @click.prevent="send">{{ (success ? 'успешно отправлено' : 'отправить') }}</a>
              <router-link to="/about" class="btn btn-gray">назад</router-link>
          </form>
          <div class="feedback-page-footer _phone">
            <app-footer/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppFooter from '@/components/app/Footer';
import request from '@/api/axios';

export default {
  components: {
    AppFooter
  },
  data: () => ({
    name: '',
    email: '',
    text: '',
    success: false,
    error: {
      name: false,
      email: false,
      text: false
    }
  }),
  computed: {
    showForm() {
      return this.$store.state.feedback.opened;
    }
  },
  methods: {
    removeErrors() {
      this.error = {
        name: false,
        email: false,
        message: false
      }
    },
    async send() {
      this.removeErrors();
      this.success = false;
      if (!this.name.length) {
        this.error.name = true;
      }
      if (!this.email.length || !this.validateEmail(this.email)) {
        this.error.email = true;
      }
      if (!this.text.length) {
        this.error.text = true;
      }
      if (this.error.name || this.error.email || this.error.text) return false;

      await request({
        action: 'feedback',
        user: this.$store.state.user,
        options: {
          name: this.name,
          email: this.email,
          text: this.text
        }
      });

      this.success = true;
      this.name = '';
      this.email = '';
      this.text = '';
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
}
</script>
