<template>
  <div class="section profile-page profile-page-bg centered-form">
    <div class="centered-form-container">
      <div>
        <div class="center-block">
          <profile-info />
        </div>
        <div class="bottom-block profile-page-bottom-block">
          <router-link to="/profile/payment" class="btn btn-d-outlined btn-with-hover">
            <template v-if="isSubscriptionActive">способ оплаты</template>
            <template v-else>подключить</template>
          </router-link>
          <a v-if="email" target="_blank" href="/profile/email-go" class="btn btn-d-outlined btn-with-hover">перейти в
            почту</a>
          <router-link to="/profile/password-change" class="btn btn-d-outlined btn-with-hover">сменить
            пароль</router-link>
          <router-link v-if="user && user.email" to="/profile/email-change" class="btn btn-d-outlined btn-with-hover">
            сменить E-mail
          </router-link>
          <a href="#" @click.prevent="logout" class="btn btn-gray">выйти</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileInfo from '@/components/ProfileInfo';
import request from '@/api/axios';

export default {
  components: {
    ProfileInfo
  },
  data: () => ({
    email: ''
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    isSubscriptionActive() {
      return this.$store.state.user?.subscription?.active;
    }
  },
  methods: {
    async logout() {
      await request({ user: this.$store.state.user, action: 'logout' });
      this.$router.push('/');
      this.$store.commit('logout');
    },
  },
  async created() {
    const mailLink = (await request({ user: this.$store.state.user, action: 'get_link_to_mail' })).data;
    this.email = mailLink.link;
  }
}
</script>
