<template>
  <div v-if="appReady">
    <div class="section section-gradient section-gradient-article" v-if="appReady">
      <div class="section-inside articles-row">
        <article-card v-for="article in articles" :article="article" :key="'article_' + article.id"/>
      </div>
      <app-footer/>
    </div>
  </div>
</template>

<script>
import ArticleCard from '@/components/articles/Card';
import AppFooter from '@/components/app/Footer';
import request from '@/api/axios';
import config from '@/config';

export default {
  data: () => ({
    articles: [],
    appReady: false
  }),
  components: {
    ArticleCard,
    AppFooter
  },
  async created() {
    this.articles = await this.getArticles();
    this.appReady = true;
  },
  methods: {
    async getArticles() {
      const response = (await request({action: 'get_articles', user: this.$store.state.user})).data;
      if (response.articles) {
        return response.articles.map(article => {
          article.image = config.hostname + article.image;
          article.url = '/articles/' + article.code + '.html';
          return article;
        });
      }
      return [];
    }
  }
}
</script>
