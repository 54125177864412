import { render, staticRenderFns } from "./Trainings.vue?vue&type=template&id=3841aca3&scoped=true&"
import script from "./Trainings.vue?vue&type=script&lang=js&"
export * from "./Trainings.vue?vue&type=script&lang=js&"
import style0 from "./Trainings.vue?vue&type=style&index=0&id=3841aca3&lang=scss&scoped=true&"
import style1 from "./Trainings.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3841aca3",
  null
  
)

export default component.exports