<template>
<div id="app">
  <component :is="layout">
    <router-view :key="$route.name + '_' + ($route.params.code || '')"/>
  </component>
</div>
</template>

<script>
const FIXED_HEADER_OFFSET = 0;

export default {
  name: 'app',
  computed: {
    layout() {
      return (this.$route.meta.layout || 'default') + '-layout';
    },
    seo() {
      return this.$store.state.seo;
    }
  },
  watch: {
    $route: {
      async handler(to, from) {
        if (this.seo) {
          let currentSeo = this.seo[to.path];
          if (!currentSeo) {
            currentSeo = this.seo['default'];
            return false;
          }

          document.title = currentSeo.title;

          const descriptionMeta = document.getElementsByTagName('meta')["description"];
          if (descriptionMeta) {
            descriptionMeta.content = currentSeo.description;
          } else {
            const meta = document.createElement('meta');
            meta.name = 'description';
            meta.content = currentSeo.description;
            document.getElementsByTagName('head')[0].appendChild(meta);
          }

          const keywordsMeta = document.getElementsByTagName('meta')["keywords"];
          if (keywordsMeta) {
            keywordsMeta.content = currentSeo.keywords;
          } else {
            const meta = document.createElement('meta');
            meta.name = 'keywords';
            meta.content = currentSeo.keywords;
            document.getElementsByTagName('head')[0].appendChild(meta);
          }

          const h1 = document.getElementsByClassName('seo_h1')[0];
          if (h1) {
            h1.textContent = currentSeo.h1;
          } else {
            const h1Tag = document.createElement('h1');
            h1Tag.classList.add('seo_h1');
            h1Tag.textContent = currentSeo.h1;
            document.getElementsByTagName('body')[0].appendChild(h1Tag);
          }

          const h2 = document.getElementsByClassName('seo_h2')[0];
          if (h2) {
            h2.textContent = currentSeo.h2;
          } else {
            const h2Tag = document.createElement('h2');
            h2Tag.classList.add('seo_h2');
            h2Tag.textContent = currentSeo.h2;
            document.getElementsByTagName('body')[0].appendChild(h2Tag);
          }
        }
      },
      immediate: true
    }
  },
  created() {
    this.setVh();
    window.addEventListener('resize', this.setVh);
    this.$store.dispatch('closeFeedback');
    this.$store.dispatch('getSeo');
  },
  mounted() {
    this.fixHeader();
  },
  methods: {
    setVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    fixHeader() {
      const findOffset = (element) => {
        let top = 0;
        let left = 0;

        do {
          top += element.offsetTop || 0;
          left += element.offsetLeft || 0;
          element = element.offsetParent;
        } while (element);

        return {
          top: top,
          left: left
        };
      }

      const stickyHeader = document.getElementById('app');
      const headerOffset = findOffset(stickyHeader);

      window.onscroll = function () {
        // body.scrollTop is deprecated and no longer available on Firefox
        const bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        if (bodyScrollTop > headerOffset.top + FIXED_HEADER_OFFSET) {
          stickyHeader.classList.add('fixed');
        } else {
          stickyHeader.classList.remove('fixed');
        }
      };
    }
  }
};
</script>

<style lang="scss">
@import '/assets/css/main';

.seo_h1 {
  display: none !important;
}

.seo_h2 {
  display: none !important;
}
</style>
