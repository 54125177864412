<template>
  <div class="feedback-page centered-form">
    <div class="centered-form-container">
      <div>
        <div class="feedback-form-container">
          <div class="section-title _phone">Получить программу тренировок</div>
          <div class="section-title _desktop">Получить программу <br>тренировок</div>
          <div class="soc-nets">
            <div class="soc-nets-top-row" v-if="false">
              <a target="_blank" href="#" class="soc-net soc-net-fb"></a>
              <a target="_blank" href="#" class="soc-net soc-net-vk"></a>
            </div>
            <div class="soc-nets-description">Чтобы получить индивидуальный план тренировок, свяжитесь с тренером </div>
            <div class="soc-nets-bottom-row">
              <!-- <a v-if="false" target="_blank" href="https://t.me/Kristina_Beks" class="soc-net soc-net-tg">
                <img src="/images/icons/tg.svg" alt="Telegram" width="30px">
                <span>Telegram</span>
              </a> -->
              <!-- <a target="_blank" href="https://wa.me/79859993432" class="soc-net soc-net-wa">
                <img src="/images/icons/wa.svg" alt="Whatsapp" width="30px">
                <span>Whatsapp</span>
              </a> -->
              <a target="_blank" href="viber://chat/?number=79859993432" class="soc-net soc-net-vb">
                <img src="/images/icons/vb.svg" alt="Viber" width="30px">
                <span>Viber</span>
              </a>
              <!-- <a target="_blank" href="https://instagram.com/beks.kristina" class="soc-net soc-net-ig">
                <img src="/images/icons/ig.svg" alt="Инстаграм" width="30px">
                <span>Instagram</span>
              </a> -->
            </div>
            <router-link to="/trainings" class="btn btn-gray">назад</router-link>
          </div>
          <div class="feedback-page-footer _phone">
            <app-footer />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppFooter from '@/components/app/Footer';
import request from '@/api/axios';

export default {
  components: {
    AppFooter
  },
  data: () => ({
    name: '',
    email: '',
    text: '',
    success: false,
    error: {
      name: false,
      email: false,
      text: false
    }
  }),
  computed: {
    showForm() {
      return this.$store.state.feedback.opened;
    }
  },
  methods: {
    removeErrors() {
      this.error = {
        name: false,
        email: false,
        message: false
      }
    },
    async send() {
      this.removeErrors();
      this.success = false;
      if (!this.name.length) {
        this.error.name = true;
      }
      if (!this.email.length || !this.validateEmail(this.email)) {
        this.error.email = true;
      }
      if (!this.text.length) {
        this.error.text = true;
      }
      if (this.error.name || this.error.email || this.error.text) return false;

      await request({
        user: this.$store.state.user,
        action: 'feedback',
        options: {
          name: this.name,
          email: this.email,
          text: this.text
        }
      });

      this.success = true;
      this.name = '';
      this.email = '';
      this.text = '';
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
}
</script>

<style scoped>
@media screen and (min-width: 621px) {
  .centered-form-container {
    padding-top: 120px;
  }
}
</style>
