<template>
    <div class="meal" v-if="meal">
        <div class="meal-header">
            <a href="#" class="meal-close" @click.prevent="close"/>
            <div class="meal-day-number">{{ meal.day }}</div>
            <div class="meal-caption">{{ meal.title }}</div>
            <div class="meal-target">
                <div class="meal-target-label">Ваша цель</div>
                <div class="meal-target-value">{{ meal.plan }}</div>
            </div>
        </div>
        <div class="meal-body" v-if="meal.items">
            <div class="meal-item" :class="{active: item.opened}" v-for="item in meal.items">
                <div class="meal-item-header bottom-line">
                    <div class="meal-item-title" v-html="item.name"/>
                    <a href="#" @click.prevent="toggleItem(item)" class="meal-item-toggle-button"/>
                </div>
                <slide-up-down :active="item.opened">
                    <div class="meal-item-body">
                        <div v-if="item.energy" class="meal-item-consist" v-html="item.energy">
<!--                            <div class="meal-item-consist-item">-->
<!--                                <div class="meal-item-consist-item-label">Вес состава:</div>-->
<!--                                <div class="meal-item-consist-item-value">320 гр.</div>-->
<!--                            </div>-->
<!--                            <div class="meal-item-consist-item">-->
<!--                                <div class="meal-item-consist-item-label">Калорийность состава:</div>-->
<!--                                <div class="meal-item-consist-item-value">253 ккал</div>-->
<!--                            </div>-->
                        </div>
                        <div v-if="item.ingredients" class="meal-item-ingredients">
                            <div class="meal-item-ingredients-title">Ингредиенты</div>
                            <div class="meal-item-ingredients-list" v-html="item.ingredients"/>
<!--                            <ol class="meal-item-ingredients-list">-->
<!--                                <li>Морковь – 18 гр.</li>-->
<!--                                <li>Стебель сельдерея – 11 гр.</li>-->
<!--                            </ol>-->
                        </div>
                        <div v-if="item.text" class="meal-item-recipe">
                            <div class="meal-item-recipe-title">Способ приготовления</div>
                            <div class="meal-item-recipe-list" v-html="item.text"/>
<!--                            <ol class="meal-item-recipe-list">-->
<!--                                <li>Куриную грудку моем под проточной водой, выкладываем в кастрюлю, заливаем чистой-->
<!--                                    холодной-->
<!--                                    водой, отправляем на огонь. Варим курицу до готовности, около получаса с момента-->
<!--                                    закипания.-->
<!--                                    В процессе варки снимаем пенку с помощью шумовки. Готовое мясо извлекаем, отделяем-->
<!--                                    от-->
<!--                                    кости,-->
<!--                                    разделяем на волокна, оставляем.-->
<!--                                </li>-->
<!--                                <li>Морковь очищаем, моем, нарезаем кружочками, выкладываем в миску. Стебли сельдерея-->
<!--                                    очищаем с-->
<!--                                    помощью овощечистки, просто снимаем тонкую кожицу, нарезаем пластинками,-->
<!--                                    перекладываем в-->
<!--                                    миску к моркови. Репчатый лук очищаем от шелухи, режем кубиками крупно, отправляем к-->
<!--                                    остальным овощам. Чеснок очищаем, затем пропускаем через пресс.-->
<!--                                </li>-->
<!--                            </ol>-->
                        </div>
                        <div class="bon-appetit">Приятного аппетита!</div>
                    </div>
                </slide-up-down>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: {
      meal: {
        type: Object,
        default: () => ({})
      },
      top: {
        type: Number,
        default: 0
      }
    },
    methods: {
      toggleItem(item) {
        item.opened = !item.opened;
        this.$forceUpdate();
      },
      close() {
        document.body.classList.remove('active-meal');
        window.scrollTo(0, this.top);
      }
    }
  }
</script>