<template>
  <div class="section profile-page centered-form">
    <div class="centered-form-container">
      <div v-if="!success">
        <div class="center-block">
          <div class="centered">
            <div class="_desktop _back-buttons">
              <router-link to="/profile" class="back-btn header-btn"/>
            </div>
            <h3 style="margin: 0; margin-bottom: 10px;">Смена e-mail</h3>
            <div class="about-sub-text">
              Введите новый e-mail, на который хотите получать чеки на покупки
            </div>
          </div>
        </div>
        <div class="bottom-block">
          <div class="input-group">
            <input
                autocomplete="off"
                type="text"
                name="email_r"
                placeholder="e-mail"
                v-model="email"
            />
          </div>
          <div class="input-group">
            <input
                autocomplete="off"
                name="email_e"
                type="text"
                placeholder="повторите e-mail"
                v-model="emailConfirm"
            />
          </div>
          <a href="#" @click.prevent="handleSubmit" class="btn">{{ error.message || 'сменить' }}</a>
        </div>
      </div>
      <div v-else>
        <div class="center-block">
          <div class="centered">
            <div class="_desktop _back-buttons">
              <router-link to="/profile" class="back-btn header-btn"/>
            </div>
            <img src="/images/heart.svg" alt="E-mail успешно изменен">
            <h3 style="margin: 0;">E-mail успешно изменен</h3>
          </div>
        </div>
        <div class="bottom-block">
          <router-link to="/" class="btn">вернуться на главную</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/api/axios';

export default {
  data: () => ({
    email: '',
    emailConfirm: '',
    error: false,
    preload: false,
    success: false
  }),
  watch: {
    email: function () {
      this.error = false;
    },
    emailConfirm: function () {
      this.error = false;
    }
  },
  methods: {
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    async handleSubmit() {
      if (this.preload) return false;
      this.preload = true;

      if (!this.email) {
        this.error = {message: 'введите e-mail'};
        this.preload = false;
        return false;
      }

      if (!this.validateEmail(this.email)) {
        this.error = {message: 'введите корректный e-mail'};
        this.preload = false;
        return false;
      }

      if (!this.emailConfirm) {
        this.error = {message: 'подтвердите e-mail'};
        this.preload = false;
        return false;
      }

      if (this.email !== this.emailConfirm) {
        this.error = {message: 'ошибка подтверждения e-mail'};
        this.preload = false;
        return false;
      }

      let response = await request({
        action: 'change_email',
        user: this.$store.state.user,
        options: { email: this.email }
      });
      this.preload = false;

      if (response?.data?.status === true) {
        this.success = true;
        this.$store.commit('login', response.data.user);
      }
    }
  }
}
</script>
