<template>
<div class="plans-section section">
  <div class="section-inside">
    <div class="plans-title section-title">Для каждой вашей цели<br class="desktop"/> мы подобрали детальную<br class="desktop"/>
      программу питания и
      тренировок
    </div>
    <List :items="plans"/>
    <router-link to="/promo" class="btn phone">получить программу</router-link>
  </div>
</div>
</template>

<script>
import List from '@/components/app/List';

export default {
  name: 'HeroPlans',
  components: { List },
  props: {
    plans: {type: Array, require: true}
  }
}
</script>

<style scoped lang="scss">

.plans-section {

  .section-inside {
    padding-top: 40px;
    padding-bottom: 50px;

    @media screen and (min-width: 621px) {
      padding-bottom: 145px;
      padding-top: 130px;
      background-image: url('/images/plans-section-redesign.png');
      background-repeat: no-repeat;
      background-position: bottom 60px right;
      background-size: 525px auto;
    }
  }

  .phone {
    display: block;

    @media screen and (min-width: 621px) {
      display: none;
    }
  }

  .plans-title {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    letter-spacing: 0.004em;

    .desktop {
      display: none;
    }

    @media screen and (min-width: 621px) {
      margin-bottom: 93px;
      font-size: 64px;
      line-height: 77px;
      letter-spacing: -0.006em;

      .desktop {
        display: block;
      }
    }
  }
}
</style>