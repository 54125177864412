<template>
  <div>
    <div class="section consultations-page section-gradient section-gradient-article" v-if="appReady">
      <div class="section-inside">
        <div class="section-title">
          Консультация<br class="_desktop"> специалиста
        </div>
        <div class="consultations-page-description">
          Наши специалисты помогут ответить на любые вопросы.
        </div>
        <div class="consultation-list" v-if="consultants">
          <div class="consultation-item" :class="{ active: consultant.form }" v-for="consultant in consultants"
            :key="'consultant_' + consultant.id">
            <div class="consultation-image" v-if="consultant.image">
              <img :src="consultant.image" :alt="consultant.name">
            </div>
            <div class="consultation-container">
              <div class="consultation-title">{{ consultant.name }}</div>
              <div class="consultation-item-info" v-if="!consultant.form">
                <div v-if="consultant.description" class="consultation-description" v-html="consultant.description" />
                <div class="more-container">
                  <div class="consultation-more-container">
                    <a @click.prevent="open(consultant)" href="#" class="consultation-more">
                      <template v-if="consultant.opened">
                        <span>скрыть</span>
                        <img src="/images/arrow-down.svg" class="rotate" alt="скрыть">
                      </template>
                      <template v-else>
                        <span>подробнее</span>
                        <img src="/images/arrow-down.svg" alt="подробнее">
                      </template>
                    </a>
                  </div>
                  <slide-up-down :active="consultant.opened">
                    <div class="consultant-more-info">
                      <div class="consultant-more-info-container" v-html="consultant.detail_text" />
                    </div>
                  </slide-up-down>
                </div>
                <a href="#" @click.prevent="feedback(consultant, 'phone')" class="btn _phone">задать вопрос</a>
                <a href="#" @click.prevent="feedback(consultant, 'desktop')" class="btn _desktop">задать вопрос</a>
              </div>
              <div class="consultant-form" v-else>
                <feedback :consultantId="parseFloat(consultant.id)" :inline="true" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <app-footer />
    </div>
  </div>
</template>

<script>
import AppFooter from '@/components/app/Footer';
import request from '@/api/axios';
import config from '@/config';
import Feedback from '@/components/app/Feedback';

export default {
  data: () => ({
    consultants: [],
    appReady: false
  }),
  components: {
    AppFooter,
    Feedback
  },
  async created() {
    this.consultants = await this.getConsultants();
    this.appReady = true;
  },
  methods: {
    feedback(consultant, view) {
      if (view === 'phone') this.$store.dispatch('openFeedback', consultant.id);
      if (view === 'desktop') {
        consultant.form = true;
        this.$forceUpdate();
      }
    },
    open(consultant) {
      consultant.opened = !consultant.opened;
      this.$forceUpdate();
    },
    async getConsultants() {
      const response = (await request({ action: 'get_consultants', user: this.$store.state.user })).data;
      if (response.consultants) {
        return response.consultants.map(consultant => {
          consultant.image = config.hostname + consultant.image;
          return consultant;
        });
      }
      return [];
    }
  }
}
</script>
