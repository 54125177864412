<template>
  <div class="section profile-page centered-form">
    <div class="centered-form-container">
      <div>
        <div class="center-block">
          <profile-info/>
        </div>
        <div class="bottom-block profile-page-bottom-block" v-if="user">
          <div
              class="payment-item"
              :class="{active: user.subscription && user.subscription.payment === 'tele2' && user.subscription.active}"
              @click="activateTele2"
          >
            <div class="payment-item-title">Счет мобильного телефона</div>
            <div class="payment-item-sub-title">только Теле2</div>
          </div>
          <div
              class="payment-item"
              :class="{active: user.subscription && user.subscription.payment === 'card' && user.subscription.active}"
              @click="yaPay"
          >
            <div class="payment-item-title">Банковская Карта</div>
            <div class="payment-item-sub-title">329 рублей в месяц</div>
          </div>
          <router-link to="/profile" class="btn-outlined">отменить</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileInfo from '@/components/ProfileInfo';

export default {
  components: {
    ProfileInfo
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    async yaPay() {
      this.$router.push({
        name: 'promo',
        params: {yaPayWidget: true}
      });
    },
    activateTele2() {
      if (this.user.subscription.payment === 'tele2' && this.user.subscription.active) return false;
      this.$router.push({
        name: 'promo',
        params: {
          activateTele2: true,
          phone: this.user.phone.formatted
        }
      });
    }
  }
}
</script>