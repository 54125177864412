<template>
  <div class="feedback-page">
    <div class="feedback-form-container">
      <div>
        <div class="section-title">Test</div>

        <video-player
            class="video-player-box"
            ref="videoPlayer"
            :options="playerOptions"
            :playsinline="true"
            customEventName="customstatechangedeventname"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @statechanged="playerStateChanged($event)"
            @ready="playerReadied"
            @contextmenu="e => e.preventDefault()"
        >
        </video-player>

      </div>

      <div class="feedback-page-footer">
        <app-footer/>
      </div>
    </div>
  </div>
</template>

<script>
import AppFooter from '@/components/app/Footer';

export default {
  components: {
    AppFooter
  },
  data() {
    return {
      playerOptions: {
        width: 1000,
        // videojs options
        muted: false,
        autoplay: true,
        language: 'ru',
        // playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: "http://meal-deal.ru/upload/video/1.mp4"
        }],
        // poster: "/static/images/author.jpg",
      }
    }
  },
  mounted() {
    console.log('this is current player instance object', this.player)
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    // listen event
    onPlayerPlay(player) {
      console.log('player play!', player)
    },
    onPlayerPause(player) {
      console.log('player pause!', player)
    },
    // ...player event

    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log('player current update state', playerCurrentState)
    },

    // player is ready
    playerReadied(player) {
      console.log('the player is readied', player)
      // you can use it to do something...
      // player.[methods]
      player.currentTime(1);
    }
  }
}
</script>

<style>
.video-player-box {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

html .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -22.5px;
  margin-left: -45px;
}
</style>