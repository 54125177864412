<template>
    <span v-if="plan" class="plans-page-item">
        <h3 v-if="showCaption">Ваша цель</h3>
        <img v-if="plan.image" :src="plan.image" :alt="plan.name">
        <span class="plans-page-item-title">{{ plan.name }}</span>
        <span v-if="plan.kkal" class="plans-page-item-description">{{ plan.kkal }}</span>
    </span>
</template>

<script>
  export default {
    props: {
      plan: {
        type: Object,
        default: () => ({})
      },
      showCaption: {
        type: Boolean,
        default: false
      }
    }
  }
</script>