<template>
<div class="section profile-page centered-form">
  <div class="centered-form-container">
    <div>
      <template v-if="step === 1">
        <div class="center-block">
          <div class="centered">
            <h3 style="margin: 0; margin-bottom: 10px;">
              <template v-if="isMail">Вход в почту</template>
              <template v-else>Регистрация и вход</template>
            </h3>
            <div class="about-sub-text">
              Введите ваш номер телефона
              для входа или регистрации
              нового пользователя
            </div>
          </div>
        </div>
        <div class="bottom-block">
          <div class="input-group">
            <input
                type="tel"
                placeholder="телефон"
                v-mask="'+7 (###) ### - ## - ##'"
                v-model="phone"
                key="phone"
                v-on:keyup.enter="handleSubmit"
            />
          </div>
          <a href="#"
             @click.prevent="handleSubmit"
             class="btn"
             :class="{error}">
            {{ error.message || 'продолжить' }}
          </a>

          <template v-if="$route.name !== 'login' && $route.name !== 'mail'">
            <template v-if="!['password-change', 'payment'].includes($route.name)">
              <router-link v-if="$route.name !== 'profile'" to="/profile" class="btn btn-gray">назад</router-link>
              <router-link :to="returnUrl" v-else class="btn btn-gray">назад</router-link>
            </template>
            <template v-else>
              <router-link to="/profile" class="btn btn-gray">назад</router-link>
            </template>
          </template>
          <template v-else>
            <router-link v-if="isMail" to="/" class="btn btn-gray">вернуться на главную</router-link>
            <router-link v-else :to="returnUrl" class="btn btn-gray">назад</router-link>
          </template>

          <a v-if="!isMail" href="/upload/offer.pdf" target="_blank" class="profile-offer-link">Оферта</a>

          <div class="center-form-footer" v-if="false">
            <p>Нажимая на кнопку “Продолжить” и подтверждая номер телефона кодом из SMS, Вы принимаете <a href="/upload/offer.pdf"
                                                                                                          target="_blank">Условия
              использования
              сервиса "Meal Deal"</a>, даете согласие на обработку своих персональных данных и соглашаетесь с
              <router-link to="/policy">условиями политики
                конфиденциальности
              </router-link>
              . Стоимость услуги 12 рублей в день, оплата со счета телефона. Первые 2 дня предоставляются
              бесплатно.
            </p>

            <template v-if="false">
              <p>Оплата осуществляется переводом с лицевого счета абонента Tele2
                на условиях <a href="https://f.tele2.ru/offer/" target="_blank">оферты</a> и условиях <a
                    href="https://единаякасса.рф/wp-content/uploads/Oferta_platezhnogo_servisa_Tele2_RNKO.pdf"
                    target="_blank">оферты</a> ООО РНКО "Единая Касса".</p>
            </template>
          </div>
        </div>
      </template>

      <template v-if="step === 2">
        <div class="center-block">
          <div class="centered">
            <h3 style="margin: 0; margin-bottom: 10px;">Регистрация</h3>
            <div class="about-sub-text">
              Данный номер телефона еще не зарегистрирован, задайте пароль для входа. Пароль должен содержать минимум 6 символов,
              любые цифры и/или буквы.
            </div>
          </div>
        </div>
        <div class="bottom-block">
          <div class="input-group">
            <input
                type="password"
                placeholder="пароль"
                v-model="password"
                key="password"
                v-on:keyup.enter="handleSubmit"
            />
          </div>
          <div class="input-group">
            <input
                type="password"
                placeholder="пароль еще раз"
                v-model="passwordConfirm"
                key="passwordConfirm"
                v-on:keyup.enter="handleSubmit"
            />
          </div>
          <a href="#"
             @click.prevent="handleSubmit"
             class="btn"
             :class="{error}">
            {{ error.message || 'зарегистрироваться' }}
          </a>
          <div class="profile-offer-link" v-if="false" style="text-decoration: none; display: block; margin-bottom: 0;">Первые 2
            дня бесплатно
          </div>
          <a href="/upload/offer.pdf" target="_blank" class="profile-offer-link">Оферта</a>
        </div>
      </template>

      <template v-if="step === 3">
        <div class="center-block">
          <div class="centered">
            <h3 style="margin: 0; margin-bottom: 10px;">Вход</h3>
            <div class="about-sub-text">
              Данный номер телефона уже зарегистрирован, введите пароль для входа.
            </div>
          </div>
        </div>
        <div class="bottom-block">
          <div class="input-group">
            <input
                type="password"
                placeholder="пароль"
                v-model="password"
                key="password"
                v-on:keyup.enter="handleSubmit"
            />
          </div>
          <a href="#"
             @click.prevent="handleSubmit"
             class="btn"
             :class="{error}">
            {{ error.message || 'войти' }}
          </a>
          <a href="#"
             class="btn-outlined btn-gray"
             @click.prevent="step = 4"
          >
            забыл пароль
          </a>
          <a href="/upload/offer.pdf" target="_blank" class="profile-offer-link">Оферта</a>
        </div>
      </template>

      <template v-if="step === 4">
        <div class="center-block">
          <div class="centered">
            <h3 style="margin: 0; margin-bottom: 10px;">Восстановление доступа</h3>
            <div class="about-sub-text">
              Чтобы создать новый пароль, введите код из смс
            </div>
          </div>
        </div>
        <div class="bottom-block">
          <div class="input-group">
            <input
                type="number"
                placeholder="код"
                v-model="code"
                v-mask="'####'"
                key="code"
                v-on:keyup.enter="handleSubmit"
            >
          </div>
          <input
              type="submit"
              :value="codeSubmitButton"
              class="btn"
              @click.prevent="sendCode"
              :disabled="codeSubmitDisabled"
              v-show="code.length !== 4"
              v-on:keyup.enter="handleSubmit"
          >
          <input
              type="submit"
              :value="error.message || 'продолжить'"
              class="btn"
              @click.prevent="handleSubmit"
              v-show="code.length === 4"
          >
          <a href="/upload/offer.pdf" target="_blank" class="profile-offer-link">Оферта</a>
        </div>
      </template>

      <template v-if="step === 5">
        <div class="center-block">
          <div class="centered">
            <h3 style="margin: 0; margin-bottom: 10px;">Восстановление доступа</h3>
            <div class="about-sub-text">
              Задайте новый пароль для входа.<br>
              Пароль должен содержать минимум 6 символов, любые цифры и/или буквы.
            </div>
          </div>
        </div>

        <div class="bottom-block">
          <div class="input-group">
            <input
                type="password"
                placeholder="пароль"
                v-model="password"
                key="password"
            >
          </div>
          <div class="input-group">
            <input
                type="password"
                placeholder="пароль еще раз"
                v-model="passwordConfirm"
                key="passwordConfirm"
            >
          </div>
          <input
              type="submit"
              :value="error.message || 'сменить пароль'"
              class="btn"
              @click.prevent="handleSubmit"
          >
          <a href="/upload/offer.pdf" target="_blank" class="profile-offer-link _phone">Оферта</a>
        </div>
      </template>
    </div>
  </div>
</div>
</template>

<script>
import request from '@/api/axios';
import eventsBus from '@/api/events';

export default {
  data: () => {
    return {
      phone: '',
      password: '',
      passwordConfirm: '',
      code: '',
      step: 1,
      error: false,
      time: 30,
      seconds: 30,
      codeSubmitDisabled: true,
      returnUrl: '/',
      referrer: false
    }
  },
  computed: {
    isMail() {
      return this.$route.meta.mail;
    },
    codeSubmitButton: function () {
      if (this.time === 0) return 'отправить еще раз';
      return 'отправить еще раз 0:' + (this.time > 9 ? this.time : '0' + this.time);
    }
  },
  created() {
    if (this.$route.query.ref === 'refreg' && this.$route.query.phone) {
      this.phone = '+' + this.$route.query.phone;
      this.referrer = true;
      this.handleSubmit();
    }
  },
  watch: {
    $route(toPath, fromPath) {
      this.closeSiteMenu();
      document.body.classList.remove('active-meal');
      if (fromPath.path.indexOf('profile') === -1) this.returnUrl = fromPath.path;
    },
    phone: function () {
      this.error = false;
    },
    agreement: function () {
      this.error = false;
    },
    password: function () {
      this.error = false;
    },
    passwordConfirm: function () {
      this.error = false;
    },
    code: function () {
      this.error = false;
    },
    step: function (newStep, oldStep) {
      if (newStep === 4 && oldStep !== 4) {
        this.sendCode();
      }
    }
  },
  mounted() {
    eventsBus.$emit('page-ready');

    if (this.$route.params) {
      if (this.$route.params.step && this.$route.params.phone) {
        this.step = this.$route.params.step;
        this.phone = this.$route.params.phone;
      }
    }

    let hash = window.location.hash;
    if (hash) {
      if (hash.indexOf('phone')) {
        this.step = 3;
        this.phone = decodeURI(hash.replace('#phone=', ''));
      }
    }
  },
  methods: {
    async handleSubmit() {
      let phone = this.phone.replace(/\s|\(|\)|-/g, '');

      if (this.step === 1) {
        if (phone.length !== 12) {
          if (phone.length === 0) {
            this.error = {message: 'введите номер телефона'};
            return false;
          }
          this.error = {message: 'номер указан неверно'};
          return false;
        }

        const checkUser = (await request({action: 'check_user', user: this.$store.state.user, options: {phone}})).data;

        if (checkUser.status !== true) {
          this.step = 2;
          // если это реферальная программа и пользователь не найден, то мы его как бы регистрируем с фейковым паролем
          // скрипт идёт дальше с step=2
          if (this.referrer) {
            const password = '123456';
            this.password = password;
            this.passwordConfirm = password;
          } else return false;
        } else {
          this.step = 3;
          return false;
        }
      }

      if (this.step === 2 || this.step === 5) {
        if (!this.password) {
          this.error = {message: 'введите пароль'};
          return false;
        }

        if (this.password?.length < 6) {
          this.error = {message: 'пароль слишком короткий'};
          return false;
        }

        if (!this.passwordConfirm) {
          this.error = {message: 'подтвердите пароль'};
          return false;
        }

        if (this.password !== this.passwordConfirm) {
          this.error = {message: 'ошибка подтверждения пароля'};
          return false;
        }

        let response = null;
        if (this.step === 2) {
          response = await request({
            user: this.$store.state.user,
            action: 'register',
            options: {
              phone,
              password: this.password,
              password_confirm: this.passwordConfirm
            }
          });
        }
        if (this.step === 5) {
          response = await request({
            user: this.$store.state.user,
            action: 'change_password',
            options: {
              phone,
              password: this.password,
              password_confirm: this.passwordConfirm
            }
          });
        }

        if (response?.data?.status === true) {
          this.$store.commit('login', response.data.user);

          if (!this.isMail) {
            const backUrl = this.$route.params.backUrl || this.$route.query.backUrl || null;
            if (backUrl != null) {
              this.$router.push(backUrl);
            } else {
              this.$router.push('/profile');
            }
          } else {
            const mailLink = (await request({user: this.$store.state.user, action: 'get_link_to_mail'})).data;
            if (mailLink.link) {
              window.location.href = mailLink.link;
            } else {
              this.$router.push('/profile');
            }
          }
        }
        return false;
      }

      if (this.step === 3) {
        if (this.password.length === 0) {
          this.error = {message: 'введите пароль'};
          return false;
        }

        const login = (await request({user: this.$store.state.user, action: 'login', options: {phone, password: this.password}})).data;
        if (login.error === true) {
          this.error = {message: 'пароль неверный'};
          return false;
        }

        console.log('q1', login.user);
        this.$store.commit('login', login.user);

        if (!this.isMail) {
          const backUrl = this.$route.params.backUrl || this.$route.query.backUrl || null;
          if (backUrl != null) {
            if (login.user?.payment?.type === 1) {
              this.$router.push(backUrl);
            } else {
              // this.$router.push('/profile/payment');
              this.$router.push('/plan');
            }
          } else {
            this.$router.push('/profile');
          }
        } else {
          const mailLink = (await request({action: 'get_link_to_mail', user: this.$store.state.user})).data;
          if (mailLink.link) {
            window.location.href = mailLink.link;
          } else {
            this.$router.push('/profile');
          }
        }
      }

      if (this.step === 4) {
        let isCodeValid = (await request({user: this.$store.state.user, action: 'check_code', options: {phone, code: this.code}})).data;

        if (isCodeValid.result === false) {
        // if (isCodeValid.status === false) {
          this.error = {message: 'код неверный'};
          return false;
        }
        this.step = 5;
        return false;
      }
    },
    async sendCode() {
      this.startTimer();
      let phone = this.phone.replace(/\s|\(|\)|-/g, '');
      return await request({user: this.$store.state.user, action: 'forgot_password', options: {phone}});
    },
    startTimer() {
      this.time = this.seconds;
      this.codeSubmitDisabled = true;
      let interval = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(interval);
          this.codeSubmitDisabled = false;
        }
      }, 1000);
    }
  }
}
</script>
