<template>
  <div>
    <div class="section">
      <div class="_desktop">
        <app-header />
      </div>
      <div class="promo-header _phone">
        <router-link to="/" class="logo-container">
          <img class="header-logo" :src="$config.logo" alt="Meal Deal">
        </router-link>
        <a href="#" @click.prevent="$router.go(-1)" class="promo-back"></a>
      </div>
      <div class="section-inside">
        <div class="profile-page promo-profile-page">
          <div class="top-block">
            <div class="section-title">
              Разнообразное и вкусное меню на каждый день
            </div>
          </div>
          <div class="trainings-page">
            <div class="_t-top">
              <div class="_t-description">
                Выберите цель, которую хотите достигнуть.<br>
                Мы сделаем все остальное.
              </div>
              <List :items="plans" />
            </div>
          </div>
        </div>
      </div>
      <app-footer />
    </div>
  </div>
</template>

<script>
import request from '@/api/axios';
import config from '@/config';
import AppHeader from '@/components/app/Header';
import AppFooter from '@/components/app/Footer';
import TrainingsTopBlock from '@/components/trainings/TopBlock';
import List from '@/components/app/List'

export default {
  components: { AppHeader, AppFooter, TrainingsTopBlock, List },
  data: () => ({
    plans: [],
  }),
  async created() {
    this.plans = await this.getPlans();
    // console.log(this.plans);
  },
  methods: {
    async getPlans() {
      const response = (await request({ user: this.$store.state.user, action: 'get_plan_sections' })).data;
      if (response.sections) {
        return response.sections.map(plan => {
          plan.image = config.hostname + plan.image;
          return plan;
        });
      }
      return [];
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-page {
  min-height: auto;
}

.section-inside {
  @media screen and (min-width: 621px) {
    min-height: calc(100vh - 180px - 80px - 65px - 10px);
  }
}

.btn-phone {
  display: none;

  @media screen and (max-width: 620px) {
    display: block;
    margin-top: 20px;
  }
}

._t-title {
  font-family: 'Optima';
  margin-top: 120px;
  font-weight: 400;
  font-size: 64px;
  line-height: 57px;
  letter-spacing: -0.016em;
  color: #333;
  margin-bottom: 30px;

  @media screen and (max-width: 620px) {
    margin-top: 30px;
    font-size: 36px;
    line-height: 38px;
  }
}

._t-sub {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.016em;
  color: #000;

  @media screen and (max-width: 620px) {
    font-size: 16px;
    line-height: 28px;
  }
}

._t-top {
  background-image: url('/images/sara.svg');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 500px auto;

  @media screen and (max-width: 620px) {
    background: none;
  }
}

._t-description {
  margin-bottom: 100px;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.016em;
  color: #000;

  @media screen and (max-width: 620px) {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 30px;
  }
}

.section-title {
  font-size: 88px;
  line-height: 77px;

  @media screen and (max-width: 620px) {
    font-size: 48px;
    line-height: 46px;
  }
}

.trainings-page {
  @media screen and (max-width: 620px) {
    margin-top: 30px;
  }
}

.plans-list {
  @media screen and (max-width: 620px) {
    width: 100%;
  }
}
</style>

<style lang="scss">
footer {
  padding-top: 100px;

  @media screen and (max-width: 620px) {
    padding-top: 60px;
  }
}

.trainings-bottom-block {
  display: flex;
  margin-top: 90px;

  @media screen and (max-width: 620px) {
    display: block;
    margin-top: 30px;
    background: #f2f0eb;
    border-radius: 16px;
    padding-top: 21px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 29px;
  }

  .image {
    border: 5px solid #008a7c;
    margin-right: 194px;
    background: #f2f0eb;
    border-radius: 100%;
    width: 378px;
    flex-basis: 378px;
    height: 378px;
    flex-grow: 0;
    flex-shrink: 0;
    background-image: url('https://meal-deal.ru/upload/1.png');
    background-position: center;
    background-size: cover;

    @media screen and (max-width: 620px) {
      width: 158px;
      height: 158px;
      border-width: 2px;
      margin: 0 auto;
    }
  }

  .trainings-bottom-block-right {

    .description {
      font-weight: 400;
      letter-spacing: -0.016em;
      color: #000;
      margin-bottom: 60px;
      font-size: 22px;
      line-height: 33px;

      .desktop {
        display: block;
      }

      .phone {
        display: none;
      }

      @media screen and (max-width: 620px) {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 24px;

        .desktop {
          display: none;
        }

        .phone {
          display: block;
        }
      }

      &-title {
        margin-bottom: 30px;
        letter-spacing: -0.016em;
        color: #000;
        font-family: 'Optima';
        font-size: 48px;
        line-height: 33px;

        @media screen and (max-width: 620px) {
          font-size: 24px;
          margin-bottom: 10px;
        }
      }
    }

    .btn-container {
      @media screen and (max-width: 620px) {
        display: none;
      }

      .btn {
        max-width: 454px;
      }
    }
  }
}
</style>
