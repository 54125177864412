<template>
    <div class="feedback-page">
        <div class="feedback-form-container">
            <div>
                <div class="section-title">Контакты</div>
                <br><br>
               <div v-html="content" />
            </div>

            <div class="feedback-page-footer">
                <app-footer/>
            </div>
        </div>
    </div>
</template>

<script>
  import AppFooter from '@/components/app/Footer';

  export default {
    components: {
      AppFooter
    },
    computed: {
      content() {
        return window._content?.PROPERTY_FEEDBACK_TEXT_VALUE;
      }
    }
  }
</script>