import axios from 'axios';
import qs from 'qs';
import config from '@/config';

const axiosInstance = axios.create();

const request = async ({action, options, user}) => {
  if (!user) {
    console.error('NO USER', action);
  }
  options = { ...options, _user: JSON.stringify(user) }
  if (action !== 'get_tele2_number') {
    return await axiosInstance.post(
      config.hostname + '/api.php?action=' + action,
      qs.stringify(options),
      // (user?.login && user?.token ? {
      //   headers: {
      //     'X-USER-LOGIN': user?.login,
      //     'X-USER-TOKEN': user?.token,
      //   }
      // } : {})
    );
  } else {
    return await axiosInstance.post(
      config.hostname.replace('https', 'http') + '/__promo/index.php'
    );
  }
};

export default request;
