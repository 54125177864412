<template>
    <div class="article-detail" v-if="article && article.id">
        <div class="article-card">
            <div v-if="article.image" class="article-image-container">
                <img class="article-image" :src="article.image" :alt="article.name">
            </div>
            <div class="article-text-container">
                <div class="article-read-time" v-if="article.time">{{ article.time }} мин чтения</div>
                <div class="article-title">{{ article.name }}</div>
                <div v-if="article.preview_text" class="article-description" v-html="article.preview_text"/>
                <div v-if="article.detail_text" class="article-detail-text" v-html="article.detail_text"/>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: {
      article: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>