<template>
  <div class="section profile-page centered-form">
    <div class="centered-form-container">
      <div>
        <div class="center-block">
          <div class="centered">
            <h3 style="margin: 0; margin-bottom: 10px;">Подождите...</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/api/axios';

export default {
  async created() {
    const mailLink = (await request({action: 'get_link_to_mail', user: this.$store.state.user})).data;
    if (mailLink.link) window.location.href = mailLink.link;
    else this.$router.push({name: 'profile'});
  }
}
</script>
