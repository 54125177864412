<template>
  <div class="section-inside" v-html="content"/>
</template>

<script>
export default {
  name: 'About',
  computed: {
    content() {
      return window._content?.PREVIEW_TEXT;
    }
  }
}
</script>

<style scoped lang="scss">

.section-inside {

  ::v-deep {

    .desktop {
      display: block;

      @media screen and (max-width: 620px) {
        display: none;
      }
    }

    h1 {
      font-family: 'Optima';
      margin-bottom: 60px;

      @media screen and (max-width: 620px) {
        margin-bottom: 40px;
      }

      ._a-title-big {
        display: block;
        font-weight: 400;
        font-size: 88px;
        line-height: 77px;
        color: #333;
        letter-spacing: -0.016em;

        @media screen and (max-width: 620px) {
          font-size: 48px;
          line-height: 77px;
        }
      }

      ._a-title-small {
        display: block;
        font-weight: 400;
        font-size: 36px;
        line-height: 40px;
        letter-spacing: -0.016em;
        color: #000;

        @media screen and (max-width: 620px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }

    ._a-p1 {
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      letter-spacing: -0.016em;
      color: #333;
      padding-bottom: 20px;
      border-bottom: 1px solid #727272;
      margin-bottom: 10px;

      @media screen and (max-width: 620px) {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 19px;
      }
    }

    ._a-p2 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.016em;
      color: #000;
      font-family: 'Optima';
      margin-bottom: 60px;

      @media screen and (max-width: 620px) {
        margin-bottom: 50px;
        margin-top: 15px;
        font-size: 16px;
        line-height: 20px;
      }
    }

    h2 {
      font-weight: 400;
      font-size: 36px;
      line-height: 35px;
      letter-spacing: -0.016em;
      color: #000;
      font-family: 'Optima';
      margin-bottom: 90px;
      text-align: left;
      border: none;
      margin-top: 0;

      @media screen and (max-width: 620px) {
        margin-bottom: 36px;
        font-size: 34px;
        line-height: 33px;
      }
    }

    ul._p-list {
      counter-reset: section;

      li {
        display: flex;
        margin-bottom: 40px;
        align-items: center;
        padding: 0;
        counter-increment: section;

        @media screen and (max-width: 620px) {
          flex-direction: column;
          margin-bottom: 60px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &:before {
          display: none;
        }

        img {
          @media screen and (max-width: 620px) {
            display: none;
            //order: -1;
            //max-width: 100% !important;
            //margin-right: 0 !important;
            //margin-left: 0 !important;
          }
        }

        &:nth-child(1) {
          img {
            max-width: 376px;
            margin-right: 30px;
          }
        }

        &:nth-child(2) {
          img {
            max-width: 391px;
            margin-left: 30px;
          }
        }

        &:nth-child(3) {
          img {
            max-width: 362px;
            margin-right: 30px;
          }
        }

        ._p-item-text {
          position: relative;

          @media screen and (max-width: 620px) {
            padding-left: 83px;

            & > p {
              margin: 0;
              font-size: 15px !important;
              line-height: 20px !important;
              letter-spacing: -0.016em !important;
            }
          }

          &:before {
            display: none;

            @media screen and (max-width: 620px) {
              content: counter(section);
              display: block;
              font-weight: 400;
              font-size: 108px;
              line-height: 46px;
              letter-spacing: -0.016em;
              color: #333;
              font-family: 'Optima';
              position: absolute;
              left: 0;
              top: 20px;
            }
          }

          p {
            font-weight: 400;
            font-size: 22px;
            line-height: 28px;
            letter-spacing: -0.016em;
            color: #333;
            margin-bottom: 25px;
          }

          .btn-outlined {
            @media screen and (max-width: 620px) {
              margin-left: -83px;
              width: calc(100% + 83px);
              max-width: calc(100% + 83px);
            }
          }
        }
      }
    }
  }
}
</style>