<template>
  <div class="section">
    <div class="plan-section-inside">
      <meal :top="scrollTopPosition" :meal="currentMeal"/>
      <div class="section plan-detail-page" v-if="appReady">
        <div class="plan-detail-item-top">
          <div class="plan-detail-item-top-left">
            <plan-card :plan="plan" :show-caption="true"/>
          </div>
          <div class="plan-detail-info">
            <div v-if="plan.title" class="section-title plan-detail-item-title">{{ plan.title }}</div>
            <div v-if="plan.before_text" class="about-page-description" v-html="plan.before_text"/>
            <hr>
            <div v-if="plan.text" class="about-sub-text" v-html="plan.text"/>
            <div class="warning">
              перед тем как приступать к диете, обязательно проконсультируйтесь со своим лечащим врачом.
            </div>
          </div>
        </div>

        <div class="days-list-label _desktop">Выберите день:</div>
        <div class="days-list" v-if="plan.days">
          <div class="day-container" :class="{active: day.opened || day.active}" v-for="day in plan.days"
               :key="'day-' + day.id">
            <div class="day-caption _phone" @click.prevent="openDay(day, 'phone')">
              <div class="day-caption-text">{{ day.name }}</div>
              <div class="day-caption-arrow"/>
            </div>
            <div class="day-caption _desktop" @click.prevent="openDay(day, 'desktop')"/>
            <slide-up-down :active="day.opened" class="_phone">
              <div class="meal-card" v-for="meal in day.elements" :key="'meal-phone-'+day.id+'-'+meal.key">
                <div class="meal-title">{{ meal.key }}</div>
                <ul class="meal-list">
                  <li v-for="mealItem in meal.data" v-html="mealItem"/>
                </ul>
                <a href="#" @click.prevent="openMeal(day, meal.key, plan)"
                   class="btn-outlined btn-full-width">подробнее</a>
              </div>
            </slide-up-down>
          </div>
        </div>
        <div class="days-info _desktop">

          <div class="day-info" :class="{active: day.active}" v-for="day in plan.days"
               :key="'day-info-' + day.id" v-masonry="daysContainer" transition-duration="0s" item-selector=".meal-card-container"
               :origin-top="true" :horizontal-order="false">
            <div v-masonry-tile class="meal-card-container" v-for="meal in day.elements"
                 :key="'meal-desktop-'+day.id+'-'+meal.key">
              <div class="meal-card">
                <div class="meal-title">{{ meal.key }}</div>
                <ul class="meal-list">
                  <li v-for="mealItem in meal.data" v-html="mealItem"/>
                </ul>
                <a href="#" @click.prevent="openMeal(day, meal.key, plan)"
                   class="btn-outlined btn-full-width">подробнее</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <app-footer/>
  </div>
</template>

<script>
import AppFooter from '@/components/app/Footer';
import PlanCard from '@/components/plans/Card';
import Meal from '@/components/plans/Meal';
import request from '@/api/axios';
import config from '@/config';

export default {
  data: () => ({
    plan: {},
    appReady: false,
    currentMeal: {},
    scrollTopPosition: 0,
    daysContainer: 'daysContainer'
  }),
  components: {
    AppFooter,
    PlanCard,
    Meal
  },
  async created() {
    this.plan = await this.getPlan();
    this.appReady = true;
  },
  methods: {
    openDay(day, view) {
      if (view === 'phone') {
        day.opened = !day.opened;
        this.$forceUpdate();
      }
      if (view === 'desktop') {
        this.plan?.days.map(item => {
          item.opened = false;
          item.active = false;
          return item;
        })
        day.active = !day.active;
        this.$forceUpdate();
        this.$nextTick(() => {
          this.$redrawVueMasonry(this.daysContainer);
        });
      }
    },
    async openMeal(day, time, plan) {
      const timeId = {
        'Завтрак': 1,
        'Обед': 2,
        'Ужин': 3
      }[time];

      const response = (await request({
        user: this.$store.state.user,
        action: 'get_plan_day',
        options: {id: day.id, time: timeId}
      })).data;

      const currentMeal = {
        day: day.name,
        title: time,
        plan: plan.name,
        items: response.day
      };
      // currentMeal.items = currentMeal.items.map((item, index) => {
      //   item.opened = index === 0;
      //   return item;
      // });
      this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop;

      this.currentMeal = currentMeal;
      window.scrollTo(0, 0);
      document.body.classList.add('active-meal');
    },
    mapOrder(array, order, key) {
      array.sort(function (a, b) {
        let A = a[key];
        let B = b[key];
        if (order.indexOf(A) > order.indexOf(B)) {
          return 1;
        } else {
          return -1;
        }
      });
      return array;
    },
    async getPlan() {
      const response = (await request({
        user: this.$store.state.user,
        action: 'get_plan_section',
        options: {id: this.$route.params.id}
      })).data;

      if (response.section) {
        if (response.section.image) response.section.image = config.hostname + response.section.image;
        if (response.days) {
          response.days = response.days.map((day, index) => {
            if (day.elements) {
              day.elements = Object.keys(day.elements).map(key => {
                return {
                  key,
                  data: day.elements[key]
                }
              })
            }
            day.elements = this.mapOrder(day.elements, ['Завтрак', 'Обед', 'Ужин'], 'key');
            day.opened = index === 0;
            day.active = index === 0;
            return day;
          });
        }
        return {
          days: response.days,
          ...response.section
        }
      }
      return {};
    }
  }
}
</script>
