<template>
    <div>
        <app-header/>
        <div class="main-container">
            <slot/>
        </div>
    </div>
</template>

<script>
  import AppHeader from '@/components/app/Header';

  export default {
    components: {
      AppHeader
    }
  }
</script>