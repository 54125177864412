<template>
  <div class="feedback-container" v-if="showForm || inline">
    <div class="feedback-form-container">
      <template v-if="!inline">
        <div class="section-title">Задать вопрос специалисту</div>
        <br><br>
      </template>
      <form action="" class="feedback-form" autocomplete="off">
        <div class="input-group">
          <input v-model="name" type="text" placeholder="ваше имя" required :class="{error: error.name}">
        </div>
        <div class="input-group">
          <input v-model="email" type="text" placeholder="ваш email" required :class="{error: error.email}">
        </div>
        <div class="input-group" v-if="!inline">
          <select placeholder="выберите специалиста" required v-model="consultant_id"
                  :class="{error: error.consultant_id}"
                  :style="{color: (consultant_id === 0 ? '#666' : '#000')}">
            <option :value="0">выберите специалиста</option>
            <option v-for="item in consultants" :value="item.id">{{ item.name }}</option>
          </select>
        </div>
        <div class="input-group">
          <textarea v-model="text" placeholder="какой у вас вопрос?" required :class="{error: error.text}"/>
        </div>

        <a href="#" class="btn" @click.prevent="send">{{ (success ? 'успешно отправлено' : 'отправить') }}</a>
      </form>
    </div>
  </div>
</template>
<script>
import request from '@/api/axios';

export default {
  props: {
    inline: Boolean,
    consultantId: Number
  },
  data: () => ({
    consultants: [],
    name: '',
    email: '',
    text: '',
    success: false,
    consultant_id: 0,
    error: {
      name: false,
      email: false,
      text: false,
      consultant_id: false,
    }
  }),
  computed: {
    showForm() {
      return this.$store.state.feedback.opened;
    },
    item() {
      return this.$store.state.feedback.id;
    }
  },
  watch: {
    item(val) {
      this.consultant_id = val || 0;
    }
  },
  async created() {
    this.consultants = await this.getConsultants();
  },
  methods: {
    async getConsultants() {
      const response = (await request({action: 'get_consultants', user: this.$store.state.user})).data;
      if (response.consultants) return response.consultants;
      return [];
    },
    removeErrors() {
      this.error = {
        name: false,
        email: false,
        message: false
      }
    },
    async send() {
      console.log(this.consultant_id);

      this.removeErrors();
      this.success = false;
      if (!this.name.length) {
        this.error.name = true;
      }
      if (!this.email.length || !this.validateEmail(this.email)) {
        this.error.email = true;
      }
      if (!this.text.length) {
        this.error.text = true;
      }
      if (!this.consultant_id && !this.consultantId) {
        this.error.consultant_id = true;
      }
      if (this.error.name || this.error.email || this.error.text || this.error.consultant_id) return false;

      await request({
        action: 'consultation',
        user: this.$store.state.user,
        options: {
          name: this.name,
          email: this.email,
          text: this.text,
          consultant_id: this.consultantId || this.consultant_id
        }
      });

      this.success = true;
      this.name = '';
      this.email = '';
      this.text = '';
      this.consultant_id = 0;
    },
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
}
</script>
