<template>
  <div class="section">
    <div class="_desktop">
      <app-header/>
    </div>
    <div class="promo-header _phone">
      <router-link to="/" class="logo-container">
        <img class="header-logo" :src="$config.logo" alt="Meal Deal">
      </router-link>
      <a href="#" @click.prevent="$router.go(-1)" class="promo-back"></a>
    </div>
    <div class="section-inside">
      <div class="profile-page promo-profile-page">
        <div class="top-block">
          <div class="section-title">
            Программа тренировок для идеальной фигуры
          </div>
          <div class="promo-slider hide-scrollbar _phone">
            <div class="plan-item-small" v-for="plan in plans" :key="'plan-small-' + plan.id">
              <img v-if="plan.image" :src="plan.image" :alt="plan.name">
              <span class="plan-item-small-title">{{ plan.name }}</span>
            </div>
          </div>
        </div>
        <div class="bottom-block promo-bottom-block">

          <template v-if="step === 1">
            <div class="input-group">
              <input
                  type="tel"
                  placeholder="номер телефона"
                  v-model="phone"
                  id="phone"
                  v-mask="'+7 ### ### ## ##'"
              >
            </div>
            <a
                href="#"
                class="btn"
                :class="{preload: preload}"
                @click.prevent="handleSubmit"
            >
              {{ (response.message || 'получить доступ') }}
            </a>
          </template>

          <template v-if="step === 2 || step === 2.5">
            <div class="input-group">
              <input
                  type="number"
                  :class="{preload: preload}"
                  placeholder="введите код из SMS"
                  class="text-input"
                  v-mask="'####'"
                  v-model="code"
              >
            </div>
            <input
                type="submit"
                :value="codeSubmitButton"
                class="btn gray disabled"
                :class="{preload: preload}"
                @click.prevent="sendCode"
                :disabled="codeSubmitDisabled"
                v-show="code.length !== 4"
            >
            <!--            :value="response.message || (step === 2 ? 'доступ предоставлен' : 'зарегистрироваться')"-->
            <input
                type="submit"
                class="btn"
                :class="{preload: preload}"
                :value="response.message || 'зарегистрироваться'"
                @click.prevent="handleSubmit"
                v-show="code.length === 4"
            >
          </template>

          <template v-if="step === 2.6">
            <div style="width: 100%;">
              <div class="input-container">
                <div id="payment-form"></div>
              </div>
              <div class="input-container">
                <a
                    href="#"
                    @click.prevent="yandexPayCancel"
                    class="yandex-pay-cancel btn"
                    style="margin-left: auto; margin-right: auto;"
                >
                  Отмена
                </a>
              </div>
            </div>
          </template>

          <template v-if="step === 2.7">
            <div style="width: 100%;">
              <div class="input-container">
                <a
                    href="#"
                    class="yandex-pay-start-read btn"
                    @click.prevent="startRead"
                    style="margin-left: auto; margin-right: auto;"
                >
                  выбрать план
                </a>
              </div>
            </div>
          </template>

          <div class="promo-bottom-text _phone">
            <template v-if="step === 2.6">
              <p>Нажимая на кнопку «Получить доступ» и подтверждая номер телефона кодом
                из SMS, Вы соглашаетесь с подключением сервиса «Meal Deal» и его
                условиями. Стоимость услуги 329 рублей в месяц, оплата с банковской карты или другим доступным способом
                оплаты.</p>

              <p>В иных случаях оплата осуществляется переводом с лицевого счета абонента Tele2
                на условиях <a href="https://f.tele2.ru/offer/" target="_blank">оферты</a> и условиях <a
                    href="https://единаякасса.рф/wp-content/uploads/Oferta_platezhnogo_servisa_Tele2_RNKO.pdf"
                    target="_blank">оферты</a> ООО РНКО "Единая Касса".</p>
            </template>
            <template v-else>
              <p>Нажимая на кнопку «Получить доступ» и подтверждая номер телефона кодом из SMS, Вы соглашаетесь с
                подключением сервиса «Meal Deal» и его условиями. Стоимость услуги 12 рублей в день, оплата со счета
                телефона. Первые 2 дня бесплатно.
              </p>

              <p>Оплата осуществляется переводом с лицевого счета абонента Tele2
                на условиях <a href="https://f.tele2.ru/offer/" target="_blank">оферты</a> и условиях <a
                    href="https://единаякасса.рф/wp-content/uploads/Oferta_platezhnogo_servisa_Tele2_RNKO.pdf"
                    target="_blank">оферты</a> ООО РНКО "Единая Касса".</p>
            </template>
          </div>
        </div>
        <div class="promo-bottom-text _desktop" style="margin-top: 15px;">
          <template v-if="step === 2.6">
            <p>Нажимая на кнопку «Получить доступ» и подтверждая номер телефона кодом
              из SMS, Вы соглашаетесь с подключением сервиса «Meal Deal» и его
              условиями. Стоимость услуги 329 рублей в месяц, оплата с банковской карты или другим доступным способом
              оплаты.</p>

            <p>В иных случаях оплата осуществляется переводом с лицевого счета абонента Tele2
              на условиях <a href="https://f.tele2.ru/offer/" target="_blank">оферты</a> и условиях <a
                  href="https://единаякасса.рф/wp-content/uploads/Oferta_platezhnogo_servisa_Tele2_RNKO.pdf"
                  target="_blank">оферты</a> ООО РНКО "Единая Касса".</p>
          </template>
        </div>
      </div>
      <div class="trainings-list _desktop" style="padding-top: 0px; width: 100%;">
        <div class="promo-slider hide-scrollbar">
          <div class="plan-item-small" v-for="plan in plans" :key="'plan-small-' + plan.id">
            <img v-if="plan.image" :src="plan.image" :alt="plan.name">
            <span class="plan-item-small-title">{{ plan.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="promo-bottom-text _desktop" style="margin-top: 65px; text-align: center;" v-if="step !== 2.6">
      <p>Нажимая на кнопку «Получить доступ» и подтверждая номер телефона кодом из SMS, Вы соглашаетесь с
        подключением сервиса «Meal Deal» и его условиями. Стоимость услуги 12 рублей в день, оплата со счета
        телефона. Первые 2 дня бесплатно.
      </p>
      <p>Оплата осуществляется переводом с лицевого счета абонента Tele2
        на условиях <a href="https://f.tele2.ru/offer/" target="_blank">оферты</a> и условиях <a
            href="https://единаякасса.рф/wp-content/uploads/Oferta_platezhnogo_servisa_Tele2_RNKO.pdf"
            target="_blank">оферты</a> ООО РНКО "Единая Касса".</p>
    </div>
  </div>
</template>

<script>
import request from '@/api/axios';
import config from '@/config';
import AppHeader from '@/components/app/Header';

export default {
  components: {AppHeader},
  data: () => ({
    // tele2phone: '1',
    plans: [],
    step: 1,
    preload: false,
    response: false,
    phone: '',
    code: '',
    time: 30,
    seconds: 30,
    codeSubmitDisabled: true,
    subscription_id: ''
  }),
  computed: {
    codeSubmitButton: function () {
      if (this.time === 0) return 'отправить еще раз';
      return 'отправить еще раз 0:' + (this.time > 9 ? this.time : '0' + this.time);
    }
  },
  async created() {
    this.plans = await this.getPlans();

    if (this.$route.params.yaPay === true) {
      this.preload = false;
      this.step = 2.5;
    } else if (this.$route.params.yaPayWidget === true) {
      this.preload = false;
      await this.showYandexWidget();
    } else if (this.$route.params.activateTele2 === true) {
      if (this.$route.params.phone) {
        this.phone = this.$route.params.phone;
        let phone = this.phone.replace(/\s|\(|\)|-/g, '');
        this.preload = false;
        await request({user: this.$store.state.user, action: 'promo_check_phone', options: {phone, reactivate: true}});
        this.returnToProfile = true;
        this.step = 2;
      }
    } else {
      try {
        let res = await request({user: this.$store.state.user, action: 'get_tele2_number'});
        // this.tele2phone = JSON.stringify(res);
        if (res && res.data && res.data.phone) {
          this.phone = res.data.phone;
        }
      } catch (e) {
        console.log(e);
        // this.tele2phone = e;
      }
    }
  },
  mounted() {
    this.checkHash();
    window.addEventListener('hashchange', this.checkHash, false);
  },
  watch: {
    phone: function () {
      this.response = false;
    },
    code: function (val) {
      this.response = false;
      if (val.length === 4) this.handleSubmit();
    },
    step: function (newStep, oldStep) {
      this.hideBackground = false; // todo ?
      if (newStep === 2 && oldStep !== 2) {
        this.startTimer();
      }
    }
  },
  methods: {
    async yandexPayCancel() {
      this.$router.push({name: 'payment'});
    },

    startRead() {
      this.$router.push('/plan');
    },

    checkHash() {
      let hash = window.location.hash;
      if (hash && hash === '#success') {
        this.step = 2.7;
      }
    },

    async getPlans() {
      const response = (await request({user: this.$store.state.user, action: 'get_trainings'})).data;
      if (response.trainings) {
        return response.trainings.map(plan => {
          plan.image = config.hostname + plan.image;
          return plan;
        });
      }
      return [];
    },

    async handleSubmit() {
      if (this.preload) return false;
      this.preload = true;
      let phone = this.phone.replace(/\s|\(|\)|-/g, '');

      if (this.step === 1) {
        if (phone.length !== 12) {
          if (phone.length === 0) {
            this.response = {message: 'введите номер телефона', type: 'error'};
            this.preload = false;
            return false;
          }
          this.response = {message: 'номер указан неверно', type: 'error'};
          this.preload = false;
          return false;
        }

        const checkUser = (await request({user: this.$store.state.user, action: 'promo_check_phone', options: {phone}})).data;
        this.subscription_id = checkUser.subscription_id;
        console.log(this.subscription_id);
        if (checkUser.status === false) {
          await request({user: this.$store.state.user, action: 'send_code_other', options: {phone}});
          this.step = 2.5;
          this.preload = false;
          return false;
        } else {
          if (checkUser.login === true) { // user exists
            location.href = this.$config.hostname + '/login#phone=' + this.phone;
            this.preload = false;
            return false;
          }
          if (checkUser.exists === true) { // ussd account
            location.href = this.$config.hostname + '/login#phone=' + this.phone;
            this.preload = false;
            return false;
          }
          this.step = 2;
          this.preload = false;
          return false;
        }
      }

      if (this.step === 2) {
        if (this.response?.type === 'complete') return false;

        // let isCodeValid = (await request({action: 'check_code', options: {phone, code: this.code}})).data;
        let isCodeValid = (await request({
          user: this.$store.state.user,
          action: 'promo_check_code',
          options: {phone, code: this.code, subscription_id: this.subscription_id}
        })).data;
        if (isCodeValid.result === false) {
          this.response = {message: 'код неверный', type: 'error'};
          this.preload = false;
          return false;
        }

        this.response = {
          message: 'услуга подключена',
          type: 'complete'
        };
        this.preload = false;
        setTimeout(async () => {
          if (this.returnToProfile) {
            await request({user: this.$store.state.user, action: 'cancel_other'});
            this.$router.push({
              name: 'profile', params: {
                changePaymentMode: true
              }
            });
          } else {
            this.$router.push('/');
          }
        }, 1000);
      }

      if (this.step === 2.5) {
        const isCodeValid = (await request({user: this.$store.state.user, action: 'check_code_other', options: {code: this.code}})).data;
        if (isCodeValid.status === false) {
          this.response = {message: 'код неверный', type: 'error'};
          this.preload = false;
          return false;
        }

        await this.showYandexWidget();
      }
    },

    async sendCode() {
      this.startTimer();
      let phone = this.phone.replace(/\s|\(|\)|-/g, '');
      return await request({user: this.$store.state.user, action: 'promo_check_phone', options: {phone}});
    },

    startTimer() {
      this.time = this.seconds;
      this.codeSubmitDisabled = true;
      let interval = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          clearInterval(interval);
          this.codeSubmitDisabled = false;
        }
      }, 1000);
    },

    async showYandexWidget() {
      const yandexToken = (await request({user: this.$store.state.user, action: 'yandex_confirmation_token'})).data;
      this.hideBackground = true; // todo ?
      this.step = 2.6;
      console.log(yandexToken);

      this.$nextTick(() => {
        if (yandexToken.token) {
          const checkout = new window.YooMoneyCheckoutWidget({
            confirmation_token: yandexToken.token,
            return_url: 'http://meal-deal.ru/promo#success',
            embedded_3ds: false,
            error_callback(error) {
              console.log(error);
            }
          });
          checkout.render('payment-form');
        }
      });
    },
  }
}
</script>

<style scoped>
.profile-page {
  min-height: calc(var(--vh, 1vh) * 100 - 77px);
}
</style>
