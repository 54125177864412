<template>
  <div class="profile-button-container">
    <a href="#" @click.prevent="toggleProfileMenu" class="profile-btn header-btn" />
    <div class="profile-menu" :class="{ active: profileMenuActive }">
      <ul>
        <li><router-link to="/profile">Личный кабинет</router-link></li>
        <li><a href="#" @click.prevent="logout">Выход</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import request from '@/api/axios';

export default {
  name: 'ProfileButton',
  data: () => ({
    profileMenuActive: false
  }),
  methods: {
    toggleProfileMenu() {
      if (this.$store.state.user) this.profileMenuActive = !this.profileMenuActive;
      else this.$router.push('/profile');
    },
    async logout() {
      await request({ action: 'logout', user: this.$store.state.user });
      this.$router.push('/');
      this.$store.commit('logout');
      location.reload();
    }
  }
}
</script>

<style scoped lang="scss">
.profile-button-container {
  position: relative;
}

.profile-menu {
  display: none;
  background: #008A7C;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 8px;
  position: absolute;
  right: 0;
  top: calc(100% + 10px);

  &.active {
    display: block;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
  }

  &:before {
    right: 16px;
    border: 8px solid transparent;
    border-bottom-color: #008A7C;
  }

  &:after {
    right: 16px;
    border: 8px solid transparent;
    border-bottom-color: #008A7C;
  }

  ul {
    li {
      padding: 0;

      &:before {
        display: none;
      }

      a {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.016em;
        color: #fff;
        white-space: nowrap;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
