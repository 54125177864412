import VueRouter from 'vue-router';
import store from './store';
import VueBodyClass from 'vue-body-class';
import Vue from 'vue';
import VueYandexMetrika from 'vue-yandex-metrika';

import Index from '@/pages/Index';
import Promo from '@/pages/Promo';
import PromoNew from '@/pages/PromoNew';
import PromoTraining from '@/pages/PromoTraining';
import Trainings from '@/pages/Trainings';
import Training from '@/pages/Training';
import Articles from '@/pages/Articles';
import Article from '@/pages/Article';
import Plans from '@/pages/Plans';
import Plan from '@/pages/Plan';
import Consultations from '@/pages/Consultations';
import Policy from '@/pages/Policy';
import About from '@/pages/About';
import Profile from '@/pages/Profile';
import Login from '@/pages/Login';
import LoginNew from '@/pages/LoginNew';
import PasswordChange from '@/pages/PasswordChange';
import EmailChange from '@/pages/EmailChange';
import EmailGo from '@/pages/EmailGo';
import Payment from '@/pages/Payment';
import Feedback from '@/pages/Feedback';
import TrainingFeedback from '@/pages/TrainingFeedback';
import Contacts from '@/pages/Contacts';

import Test from '@/pages/Test';

const routes = [
  {
    path: '/',
    component: Index,
    name: 'index',
    meta: { bodyClass: 'body-index' },
  },
  {
    path: '/promo',
    component: PromoNew,
    name: 'promo',
    meta: {
      // guest: true,
      layout: 'promo'
    }
  },
  {
    path: '/promo-training',
    component: PromoTraining,
    name: 'promo-training',
    meta: {
      // guest: true,
      layout: 'promo'
    }
  },
  {
    path: '/trainings',
    component: Trainings,
    name: 'trainings',
    meta: {
      // requiresAuth: true,
      // needSubscription: true, // todo remove comment later
      layout: 'promo'
    }
  },
  {
    path: '/trainings/:id',
    component: Training,
    name: 'training',
    meta: {
      requiresAuth: true,
      needSubscription: true, // todo remove comment later
      // layout: 'promo'
    }
  },
  {
    path: '/articles',
    component: Articles,
    name: 'articles'
  },
  {
    path: '/articles/:code',
    component: Article,
    name: 'article'
  },
  {
    path: '/plan',
    component: Plans,
    name: 'plans',
    meta: {
      layout: 'promo'
    }
  },
  {
    path: '/plan/:id',
    component: Plan,
    name: 'plan',
    meta: {
      testGuard: true // todo rename testGuard
      // requiresAuth: true,
      // needSubscription: true
    }
  },
  {
    path: '/consultations',
    component: Consultations,
    name: 'consultations'
  },
  {
    path: '/policy',
    component: Policy,
    name: 'policy'
  },
  {
    path: '/about',
    component: About,
    name: 'about'
  },
  {
    path: '/profile',
    component: Profile,
    name: 'profile',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    component: LoginNew,
    name: 'login',
    meta: {
      guest: true,
      layout: 'promo'
    }
  },
  {
    path: '/profile/password-change',
    component: PasswordChange,
    name: 'password-change',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/email-change',
    component: EmailChange,
    name: 'email-change',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile/email-go',
    component: EmailGo,
    name: 'email-go',
  },
  {
    path: '/mail',
    component: LoginNew,
    name: 'mail',
    meta: {
      mail: true
    }
  },
  {
    path: '/profile/payment',
    component: Payment,
    name: 'payment',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/feedback',
    component: Feedback,
    name: 'feedback'
  },
  {
    path: '/training-feedback',
    component: TrainingFeedback,
    name: 'training-feedback'
  },
  {
    path: '/contacts',
    component: Contacts,
    name: 'contacts'
  },
  {
    path: '/test',
    component: Test,
    name: 'test'
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

Vue.use(VueYandexMetrika, {
  id: 57080467,
  router: router,
  env: process.env.NODE_ENV
  // other options
});

const vueBodyClass = new VueBodyClass(routes);

router.beforeEach(async (to, from, next) => {
  vueBodyClass.guard(to, next);

  // todo rename testGuard
  if (to.matched.some(record => record.meta.testGuard)) {
    console.log('hello', store.state.user, store.state.user?.subscription?.active);
    if (store.state.user === null || !store.state.user?.subscription?.active) {
      const queryParams = { backUrl: to.fullPath }
      if (store.state.user?.phone?.original) {
        queryParams.activateTele2 = '1';
        queryParams.phone = store.state.user.phone.original;
      }
      next({
        path: '/promo',
        query: queryParams
      });
    }
  } else {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.state.user === null) {
        next({
          path: '/login',
          query: {backUrl: to.fullPath}
        });
      } else {

        if (to.matched.some(record => record.meta.needSubscription)) {
          if (store.state.user?.subscription?.active) {
            next();
          } else {
            next({
              path: '/profile/payment',
              query: {backUrl: to.fullPath}
            });
          }
        }

        next();
      }
    } else if (to.matched.some(record => record.meta.guest)) {
      if (store.state.user === null) {
        next();
      } else {
        next({path: '/'});
      }
    } else {
      next();
    }
  }
});

export default router;
