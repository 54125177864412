<template>
  <div v-if="appReady">
    <Hero />
    <div class="articles-section section section-gradient section-gradient-index-1">
      <div class="section-inside articles-row">
        <div class="_phone">
          <article-card v-if="articles && articles[0]" :article="articles[0]" :key="'article_' + articles[0].id"/>
          <article-card v-if="articles && articles[1]" :article="articles[1]" :key="'article_' + articles[1].id"/>
        </div>
        <div class="_desktop">
          <article-card v-if="articles && articles[0]" :article="articles[0]" :key="'article_' + articles[0].id"/>
          <article-card v-if="articles && articles[1]" :article="articles[1]" :key="'article_' + articles[1].id"/>
          <article-card v-if="articles && articles[2]" :article="articles[2]" :key="'article_' + articles[2].id"/>
          <article-card v-if="articles && articles[3]" :article="articles[3]" :key="'article_' + articles[3].id"/>
        </div>
      </div>
    </div>
    <HeroPlans v-if="plans" :plans="plans" />
    <div class="articles-section section section-gradient section-gradient-index-2">
      <div class="section-inside articles-row">
        <div class="_phone">
          <article-card v-if="articles && articles[2]" :article="articles[2]" :key="'article_' + articles[2].id"/>
          <article-card v-if="articles && articles[3]" :article="articles[3]" :key="'article_' + articles[3].id"/>
        </div>
        <div class="_desktop">
          <article-card v-if="articles && articles[4]" :article="articles[4]" :key="'article_' + articles[4].id"/>
          <article-card v-if="articles && articles[5]" :article="articles[5]" :key="'article_' + articles[5].id"/>
          <article-card v-if="articles && articles[6]" :article="articles[6]" :key="'article_' + articles[6].id"/>
          <article-card v-if="articles && articles[7]" :article="articles[7]" :key="'article_' + articles[7].id"/>
        </div>
      </div>
    </div>
    <Consultants v-if="consultants" :consultants="consultants" />
    <div class="section section-gradient section-gradient-index-3">
      <div class="section-inside articles-row">
        <div class="articles-section articles-row">
          <div class="_phone">
            <article-card v-if="articles && articles[4]" :article="articles[5]" :key="'article_' + articles[5].id"/>
            <article-card v-if="articles && articles[5]" :article="articles[6]" :key="'article_' + articles[6].id"/>
          </div>
          <div class="_desktop">
            <article-card v-if="articles && articles[8]" :article="articles[8]" :key="'article_' + articles[8].id"/>
            <article-card v-if="articles && articles[9]" :article="articles[9]" :key="'article_' + articles[9].id"/>
            <article-card v-if="articles && articles[10]" :article="articles[10]" :key="'article_' + articles[10].id"/>
            <article-card v-if="articles && articles[11]" :article="articles[11]" :key="'article_' + articles[11].id"/>
          </div>
        </div>
        <div class="_desktop" style="height: 30px; width: 100%;"></div>
        <router-link to="/articles" class="btn btn-big">все статьи</router-link>
      </div>
      <app-footer/>
    </div>
  </div>
</template>

<script>
import ArticleCard from '@/components/articles/Card';
import AppFooter from '@/components/app/Footer';
import request from '@/api/axios';
import config from '@/config';
import Hero from '@/components/main-page/Hero';
import HeroPlans from '@/components/main-page/HeroPlans';
import Consultants from '@/components/main-page/Consultants';

export default {
  data: () => ({
    articles: [],
    plans: [],
    consultants: [],
    appReady: false
  }),
  components: {
    ArticleCard,
    AppFooter,
    Hero,
    HeroPlans,
    Consultants
  },
  async created() {
    this.articles = await this.getArticles();
    const plans = await this.getPlans();
    const trainings = (await this.getTrainings())?.map(i => ({...i, url: `/trainings/${i.id}`}));
    this.plans = [...plans, ...trainings];
    this.consultants = await this.getConsultants();
    this.appReady = true;
  },
  methods: {
    async getTrainings() {
      const response = (await request({action: 'get_trainings', user: this.$store.state.user})).data;
      if (response.trainings) {
        return response.trainings.map(plan => {
          plan.image = config.hostname + plan.image;
          return plan;
        });
      }
      return [];
    },
    feedback() {
      this.$store.dispatch('openFeedback');
    },
    async getConsultants() {
      const response = (await request({action: 'get_consultants', user: this.$store.state.user})).data;
      if (response.consultants) {
        return response.consultants.map(consultant => {
          consultant.image = config.hostname + consultant.image;
          return consultant;
        });
      }
      return [];
    },
    async getPlans() {
      const response = (await request({action: 'get_plan_sections', user: this.$store.state.user})).data;
      if (response.sections) {
        return response.sections.map(plan => {
          plan.image = config.hostname + plan.image;
          return plan;
        });
      }
      return [];
    },
    async getArticles() {
      const response = (await request({action: 'get_articles', user: this.$store.state.user})).data;
      if (response.articles) {
        return response.articles.map(article => {
          article.image = config.hostname + article.image;
          article.url = '/articles/' + article.code + '.html';
          return article;
        });
      }
      return [];
    }
  }
}
</script>
