<template>
<div class="section profile-page centered-form">
  <div class="centered-form-container">
    <div>
      <div class="center-block">
        <div class="centered">
          <div class="_desktop _back-buttons">
            <router-link to="/profile" class="back-btn header-btn"/>
          </div>
          <h3 style="margin: 0; margin-bottom: 10px;">Смена пароля</h3>
          <div class="about-sub-text">
            Задайте новый пароль для входа.<br>
            Пароль должен содержать минимум 6 символов, любые цифры и/или буквы.
          </div>
        </div>
      </div>
      <div class="bottom-block">
        <div class="input-group">
          <input
              autocomplete="off"
              type="password"
              name="password_r"
              placeholder="пароль"
              v-model="password"
          />
        </div>
        <div class="input-group">
          <input
              autocomplete="off"
              name="password_e"
              type="password"
              placeholder="повторите пароль"
              v-model="passwordConfirm"
          />
        </div>
        <a
            v-if="!success"
            href="#"
            @click.prevent="handleSubmit"
            class="btn"
            :class="{ error: error.message }"
        >
          {{ error.message || 'сменить' }}
        </a>
        <a v-else href="javascript:" class="btn">пароль успешно изменен</a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import request from '@/api/axios';

export default {
  data: () => ({
    password: '',
    passwordConfirm: '',
    error: false,
    preload: false,
    success: false
  }),
  watch: {
    password: function () {
      this.error = false;
    },
    passwordConfirm: function () {
      this.error = false;
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods: {
    async handleSubmit() {
      if (this.preload) return false;
      this.preload = true;

      if (!this.password) {
        this.error = {message: 'введите пароль'};
        this.preload = false;
        return false;
      }

      if (this.password?.length < 6) {
        this.error = {message: 'пароль слишком короткий'};
        return false;
      }

      if (!this.passwordConfirm) {
        this.error = {message: 'подтвердите пароль'};
        this.preload = false;
        return false;
      }

      if (this.password !== this.passwordConfirm) {
        this.error = {message: 'ошибка подтверждения пароля'};
        this.preload = false;
        return false;
      }

      let response = await request({
        user: this.$store.state.user,
        action: 'change_password',
        options: {
          phone: this.user.phone.original,
          password: this.password,
          password_confirm: this.passwordConfirm
        }
      });
      this.preload = false;
      // console.log(response);

      if (response?.data?.status === true) {
        this.success = true;
        setTimeout(() => {
          this.$router.push('/profile');
        }, 1000);
      }
    }
  }
}
</script>
