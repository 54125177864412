import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import request from '@/api/axios';

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    user: null,
    seo: null,
    feedback: {
      opened: false
    }
  }
};

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: getDefaultState,
  actions: {
    async getUser({commit, state}) {
      try {
        console.log('state.user', state.user);
        const response = (await request({user: state.user, action: 'user'})).data;
        if (response && response.user) {
          console.log('q4', response.user);
          commit('login', response.user);
        } else {
          commit('logout');
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getSeo({commit, state}) {
      try {
        const response = (await request({user: state.user, action: 'get_seo'})).data;
        if (response && response.seo) {
          commit('setSeo', response.seo);
        }
      } catch (e) {
        console.log(e);
      }
    },
    openFeedback({commit}, id = false) {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      commit('toggleFeedback', {
        opened: true,
        top,
        id
      });
      window.scrollTo(0, 0);
      document.body.classList.add('active-feedback');
    },
    closeFeedback({commit}) {
      document.body.classList.remove('active-feedback');
      commit('toggleFeedback', {opened: false});
    }
  },
  getters: {},
  mutations: {
    toggleFeedback(state, obj) {
      const top = state.feedback.top;
      state.feedback = obj;
      if (obj.opened === false) window.scrollTo(0, top);
    },
    logout(state) {
      console.log('logout');
      state.user = null;
    },
    login(state, user) {
      console.log('login', user);
      state.user = user;
    },
    setSeo(state, seo) {
      state.seo = seo;
    }
  }
});
