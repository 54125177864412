<template>
<div class="consult-section section">
  <div class="section-inside">
    <div class="consult-title section-title">Наши специалисты помогут разобраться со здоровым питанием</div>
    <router-link to="/consultations" class="btn _desktop">задать вопрос</router-link>
    <div class="consult-list">
      <VueSlickCarousel v-bind="sliderSettings">
        <router-link tag="div" to="/consultations" class="consult-item" v-for="consultant in consultants"
                     :key="'consultant_' + consultant.id">
          <img v-if="consultant.image" :src="consultant.image" :alt="consultant.name">
          <div class="consultation-container">
            <div class="consult-item-title">{{ consultant.name }}</div>
            <div v-if="consultant.description" class="consult-item-description" v-html="consultant.description"/>
          </div>
        </router-link>
      </VueSlickCarousel>
    </div>
    <a href="#" @click.prevent="feedback()" class="btn phone">задать вопрос</a>
  </div>
</div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
const mobileBreakpoint = 1200;

export default {
  name: 'Consultants',
  props: {
    consultants: { type: Array, require: true }
  },
  data: () => ({
    sliderSettings: {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      adaptiveHeight: false,
      responsive: [
        {
          breakpoint: mobileBreakpoint,
        },
        {
          breakpoint: 10000,
          settings: 'unslick'
        }
      ]
    }
  }),
  components: { VueSlickCarousel },
}
</script>

<style scoped lang="scss">

.consult-section {
  margin-bottom: 50px;
  padding-top: 230px;
  position: relative;
  top: -100px;

  @media screen and (max-width: 620px) {
    padding-top: 130px;
    margin-bottom: 0;
    top: -80px;
  }

  .phone {
    display: block;
  }

  .btn {
    margin-top: 30px;
  }

  @media screen and (min-width: 621px) {
    padding-top: 150px;
    margin-bottom: 150px;
    top: auto;
    
    .phone {
      display: none;
    }

    .btn {
      max-width: 340px;
      margin-bottom: 60px;
      margin-top: 60px;

    }
  }

  .consult-title {
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 30px;

    @media screen and (min-width: 621px) {
      font-size: 64px;
      line-height: 77px;
    }
  }

  .consult-list {

    @media screen and (min-width: 621px) {
      & > div {
        display: flex;
      }
    }
  }

  .consult-item {
    cursor: pointer;
    text-align: center;
    background: #F2F0EB;
    padding-top: 21px;
    padding-left: 33px;
    padding-right: 33px;
    padding-bottom: 25px;
    border-radius: 20px;

    @media screen and (min-width: 621px) {
      padding-top: 43px;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 45px;
      max-width: 100%;
      width: calc(50% - 10px);
      margin-left: 20px;

      &:first-child {
        margin-left: 0;
      }
    }

    img {
      border-radius: 100%;
      object-fit: cover;
      width: 159px;
      height: 159px;
      border: 3px solid #008A7C;

      @media screen and (min-width: 621px) {
        height: 201px;
        width: 201px;
        object-fit: cover;
      }
    }

    @media screen and (min-width: 621px) {

      .consultation-container {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .consult-item-title {
      margin-top: 13px;
      margin-bottom: 6px;
      text-align: center;
      letter-spacing: -0.016em;
      font-family: 'Optima';
      font-size: 24px;
      line-height: 33px;

      @media screen and (min-width: 621px) {
        font-size: 34px;
        line-height: 33px;
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }

    .consult-item-description {
      text-align: center;
      letter-spacing: -0.016em;
      text-decoration: underline;
      font-family: 'Optima';
      font-size: 18px;
      line-height: 24px;
      font-weight: 400;

      @media screen and (min-width: 621px) {
        font-size: 22px;
        line-height: 24px;
      }
    }
  }
}
</style>