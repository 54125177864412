<template>
  <div class="section section-gradient section-gradient-article" v-if="appReady">
    <div class="section-inside">
      <article-detail :article="article" :key="'article_detail_' + article.id"/>
      <div class="articles-section articles-row">
        <article-card
            v-if="otherArticles && otherArticles[0]"
            :article="otherArticles[0]"
            :key="'article_' + otherArticles[0].id"
        />
        <article-card
            v-if="otherArticles && otherArticles[1]"
            :article="otherArticles[1]"
            :key="'article_' + otherArticles[1].id"
        />
        <article-card
            v-if="otherArticles && otherArticles[2]"
            :article="otherArticles[2]"
            :key="'article_' + otherArticles[2].id"
        />
        <article-card
            v-if="otherArticles && otherArticles[3]"
            :article="otherArticles[3]"
            :key="'article_' + otherArticles[3].id"
            class="_desktop-article"
        />
        <div class="_desktop" style="height: 30px; width: 100%;"></div>
        <router-link to="/articles" class="btn btn-big">все статьи</router-link>
      </div>
    </div>
    <app-footer/>
  </div>
</template>

<script>
import ArticleDetail from '@/components/articles/Detail';
import AppFooter from '@/components/app/Footer';
import ArticleCard from '@/components/articles/Card';
import request from '@/api/axios';
import config from '@/config';

export default {
  data: () => ({
    article: null,
    otherArticles: [],
    appReady: false
  }),
  components: {
    ArticleDetail,
    AppFooter,
    ArticleCard
  },
  async created() {
    this.article = await this.getArticle();
    this.otherArticles = await this.getOtherArticles();
    this.appReady = true;
    // console.log(this.article);
    // console.log(this.otherArticles);
  },
  methods: {
    async getOtherArticles() {
      const response = (await request({action: 'get_articles', user: this.$store.state.user})).data;
      if (response.articles) {
        return response.articles
            .filter(({id}) => id !== this.article.id)
            .map(article => {
              article.image = config.hostname + article.image;
              article.url = '/articles/' + article.code + '.html';
              return article;
            })
            .sort(() => 0.5 - Math.random())
            .slice(0, 4);
      }
      return [];
    },
    async getArticle() {
      const response = (
          await request({
            action: 'get_article',
            user: this.$store.state.user,
            options: {code: this.$route.params.code.replace(".html", "")}
          })
      ).data;
      if (response.article) {
        response.article.image = config.hostname + response.article.image;
        return response.article;
      }
      return null;
    }
  }
}
</script>
