<template>
  <div class="section">
    <div class="section-inside">
      <div class="profile-page promo-profile-page">
        <div class="top-block">
          <div class="section-title">
            Программа тренировок для идеальной фигуры
          </div>
        </div>
        <div class="trainings-page">
          <trainings-top-block/>
          <div class="training-player-container">
            <video-player
                v-if="playerOptions.sources[0].src"
                class="video-player-box"
                ref="videoPlayer"
                :options="playerOptions"
                :playsinline="true"
                customEventName="customstatechangedeventname"
                @contextmenu="test"
            >
            </video-player>
            <div class="training-player-container-title">{{ this.training.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <app-footer/>
  </div>
</template>

<script>
import request from '@/api/axios';
import config from '@/config';
import AppHeader from '@/components/app/Header';
import AppFooter from '@/components/app/Footer';
import TrainingsTopBlock from '@/components/trainings/TopBlock';

export default {
  components: {AppHeader, AppFooter, TrainingsTopBlock},
  data() {
    return {
      training: {},
      vw: 0
    }
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    },
    playerOptions() {
      return {
        height: this.vw > 1000 ? 600 : 190,
        muted: false,
        autoplay: true,
        language: 'ru',
        // playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: this.training.video
        }],
      }
    }
  },
  methods: {
    test(e) {
      console.log('asda');
      e.preventDefault()
    }
  },
  async created() {
    this.vw = window.innerWidth;
    this.training = (
        await request({
          user: this.$store.state.user,
          action: 'get_training',
          options: {id: this.$route.params.id}
        })
    )?.data?.training;
  },
}
</script>

<style scoped lang="scss">
.training-player-container {
  margin-top: 90px;
  background: #f2f0eb;
  padding-top: 90px;
  padding-left: 33px;
  padding-right: 33px;
  padding-bottom: 50px;
  border-radius: 10px;

  @media screen and (max-width: 620px) {
    margin-top: 60px;
    padding-top: 40px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.training-player-container-title {
  margin-top: 80px;
  font-family: 'Optima';
  font-size: 34px;
  line-height: 33px;
  text-align: center;
  letter-spacing: -0.016em;
  color: #000;

  @media screen and (max-width: 620px) {
    margin-top: 50px;
  }
}

.video-player-box {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

html .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -22.5px;
  margin-left: -45px;
}

footer {
  padding-top: 100px;
}
</style>
