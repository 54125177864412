<template>
  <div class="article-card" v-if="article && article.id">
    <router-link v-if="article.image" :to="article.url" class="article-image-container">
      <img class="article-image" :src="article.image" :alt="article.name">
    </router-link>
    <div class="article-text-container">
      <div v-if="article.time" class="article-read-time">{{ article.time }} мин чтения</div>
      <router-link :to="article.url" class="article-title">{{ article.name }}</router-link>
      <div v-if="article.preview_text" class="article-description" v-html="article.preview_text"/>
      <div class="article-button-container">
        <router-link :to="article.url" class="btn-outlined">читать статью</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    article: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>