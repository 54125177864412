<template>
  <div class="section policy-page">
    <div class="section-inside">
      <div class="policy-text">
        <h3>Политика обработки персональных данных</h3>
        <div class="policy-text-container" v-html="content" />
      </div>
    </div>
    <app-footer/>
  </div>
</template>

<script>
import AppFooter from '@/components/app/Footer';

export default {
  components: {
    AppFooter
  },
  computed: {
    content() {
      return window._content?.DETAIL_TEXT;
    }
  }
}
</script>