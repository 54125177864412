<template>
  <div>
    <header id="sticky" class="section">
        <template v-if="showFeedbackForm">
          <a @click.prevent="closeSiteMenu" href="#" class="menu-btn header-btn"></a>
          <router-link @click.native="closeSiteMenu" to="/">
            <img class="header-logo" :src="$config.logo" alt="Meal Deal">
          </router-link>
        </template>
        <template v-else>
          <a @click.prevent="toggleMenu" href="#" class="_phone menu-btn header-btn"></a>
          <router-link @click.native="closeSiteMenu" to="/">
            <img class="header-logo" :src="$config.logo" alt="Meal Deal">
          </router-link>
        </template>

      <div class="site-menu-desktop _desktop">
        <router-link @click.native="closeSiteMenu" to="/articles">Статьи</router-link>
        <router-link @click.native="closeSiteMenu" to="/plan">Планы питания</router-link>
        <router-link @click.native="closeSiteMenu" to="/trainings">Тренировки</router-link>
        <router-link @click.native="closeSiteMenu" to="/consultations">Консультация</router-link>
        <router-link @click.native="closeSiteMenu" to="/about">О нас</router-link>
      </div>

      <template v-if="$route.name !== 'login'">
        <template v-if="!['payment'].includes($route.name)">
          <router-link v-if="false && $route.name !== 'profile'" to="/profile" class="profile-btn header-btn"/>
          <ProfileButton v-if="$route.name !== 'profile'"/>
          <router-link :to="returnUrl" v-else class="profile-close-btn header-btn"/>
        </template>
        <template v-else>
          <router-link to="/profile" class="back-btn header-btn"/>
        </template>
      </template>
      <template v-else>
        <!-- @click.prevent="$router.go(-1)" -->
        <router-link :to="returnUrl" class="back-btn header-btn"/>
      </template>
    </header>
    <div class="site-menu _phone">
      <div class="site-menu-container">
        <div class="site-menu-nav">
          <div class="site-menu-nav-item" v-if="false">
            <router-link @click.native="closeSiteMenu" to="/">Главная</router-link>
          </div>
          <div class="site-menu-nav-item">
            <router-link @click.native="closeSiteMenu" to="/articles">Статьи</router-link>
          </div>
          <div class="site-menu-nav-item">
            <router-link @click.native="closeSiteMenu" to="/plan">Планы питания</router-link>
          </div>
          <div class="site-menu-nav-item">
            <router-link @click.native="closeSiteMenu" to="/trainings">Тренировки</router-link>
          </div>
          <div class="site-menu-nav-item">
            <router-link @click.native="closeSiteMenu" to="/consultations">Консультация</router-link>
          </div>
          <div class="site-menu-nav-item">
            <router-link @click.native="closeSiteMenu" to="/about">О нас</router-link>
          </div>
        </div>
        <div class="site-menu-footer">
          <div class="site-menu-footer-item">
            <a href="/upload/offer.pdf" target="_blank">Оферта</a>
          </div>
          <div class="site-menu-footer-item">
            <router-link @click.native="closeSiteMenu" to="/policy">Политика конфиденциальности</router-link>
          </div>
        </div>
      </div>
    </div>
    <feedback/>
  </div>
</template>

<script>
import Feedback from '@/components/app/Feedback';
import ProfileButton from '@/components/app/ProfileButton';

export default {
  components: {
    Feedback,
    ProfileButton
  },
  watch: {
    $route(toPath, fromPath) {
      this.closeSiteMenu();
      document.body.classList.remove('active-meal');
      if (fromPath.path.indexOf('profile') === -1) this.returnUrl = fromPath.path;
    }
  },
  data: () => ({
    returnUrl: '/',
    activeMenu: false,
    scrollTopPosition: 0
  }),
  async created() {
    // if (location.href.indexOf('localhost') === -1)
    await this.$store.dispatch('getUser');
  },
  computed: {
    showFeedbackForm() {
      return this.$store.state.feedback.opened;
    }
  },
  methods: {
    toggleMenu() {
      this.activeMenu = !this.activeMenu;
      if (this.activeMenu) this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop;
      document.body.classList.toggle('active-menu');
      if (!this.activeMenu) window.scrollTo(0, this.scrollTopPosition);
    },
    closeSiteMenu() {
      document.body.classList.remove('active-menu');
      this.$store.dispatch('closeFeedback');
    },
  }
}
</script>
