<template>
  <div class="centered">
    <img src="/images/profile-image-redesign.png?v=3" alt="Профиль" class="profile-image">
    <div class="profile-status" :class="{ 'profile-status--error': isError }" v-html="status"></div>
    <div class="profile-phone" v-if="user && user.phone && user.phone.formatted">
      {{ user.phone.formatted }}
    </div>
    <div class="profile-email" v-if="user && user.email">Ваш E-mail
      {{ user.email }}
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    user() {
      return this.$store.state.user;
    },
    status() {
      if (!this.$store.state.user?.subscription?.active) return 'Услуга отключена. <br/>Оплатите удобным для вас способом';
      if (this.$store.state.user?.subscription?.payment === 'tele2') return 'Услуга подключена и оплачена <br/>с помощью мобильного телефона';
      if (this.$store.state.user?.subscription?.payment === 'card') return 'Услуга подключена и оплачена <br/>с помощью банковской карты';
      return 'Услуга не подключена';
    },
    isError() {
      return !this.$store.state.user?.subscription?.active;
    }
  }
}
</script>

<style scoped>
.profile-page .center-block .profile-status.profile-status--error {
  border-color: #E23939;
  color: #E23939;
}
</style>
