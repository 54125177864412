<template>
 <div class="success">
   <div class="success-container">
     <div class="success-title">Поздравляем!</div>
     <div class="success-description">Услуга успешно подключена.<br/>
       Твой путь комплексной заботы о себе.</div>
     <router-link :to="backUrl" class="btn">начать</router-link>
     <div class="success-redirect-text">
       Через 5 сек вы будете перенаправлены автоматически.
       Если этого не произошло автоматически, пожалуйста,
       <router-link :to="backUrl">нажмите здесь</router-link>
     </div>
   </div>

   <!-- to make styles works only with this component -->
   <component is="style">
     .main-container {
      background: linear-gradient(180deg, rgba(222, 209, 227, 0) 0%, #DED1E3 34.38%, #EAD7D7 56.14%, rgba(234, 216, 215, 0) 98.89%);
     }
     header {
      background: transparent !important;
     }
   </component>
 </div>
</template>

<script>
export default {
  name: 'Success',
  data: () => ({
    goto: '/plan',
    timeoutId: null
  }),
  computed: {
    backUrl() {
      return this.$route.params.backUrl || this.$route.query.backUrl || this.goto;
    }
  },
  async created() {
    console.log('backUrl', this.backUrl);
    await this.$store.dispatch('getUser');
    this.timeoutId = setTimeout(() => { this.$router.push(this.backUrl); }, 5000);
  },
  beforeDestroy() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }
}
</script>

<style scoped lang="scss">
  .success {
    min-height: calc(100vh - 145px);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;

    @media screen and (max-width: 1060px) {
      background-image: url('/images/success-background-mobile.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    @media screen and (max-width: 620px) {
      min-height: calc(100vh - 77px);
    }

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      background-size: contain;
      background-repeat: no-repeat;

      @media screen and (max-width: 1060px) {
        display: none;
      }
    }

    &:before {
      background-image: url('/images/success-image-1.png');
      width: 427px;
      height: 447px;
      margin-top: -224px;
      left: 50%;
      margin-left: -676px;

      @media screen and (max-width: 1250px) {
        width: 327px;
        height: 347px;
        margin-top: -170px;
        margin-left: 0;
        left: 40px;
      }
    }

    &:after {
      background-image: url('/images/success-image-2.png');
      width: 368px;
      height: 442px;
      margin-top: -221px;
      right: 50%;
      margin-right: -612px;

      @media screen and (max-width: 1250px) {
        width: 348px;
        height: 342px;
        margin-top: -170px;
        margin-right: 0;
        right: 0;
      }
    }
  }

  .success-container {
    max-width: 345px;
    margin-top: -145px;

    @media screen and (max-width: 620px) {
      margin-top: -77px;
    }
  }

  .success-title {
    font-family: 'Optima';
    font-weight: 400;
    font-size: 48px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -0.016em;
    color: #333333;
    margin-bottom: 20px;

    @media screen and (max-width: 620px) {
      font-size: 34px;
      line-height: 33px;
      margin-bottom: 10px;
    }
  }

  .success-description {
    font-family: 'Optima';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.016em;
    color: #000000;
    margin-bottom: 90px;

    @media screen and (max-width: 620px) {
      font-size: 14px;
      margin-bottom: 75px;
    }
  }

  .btn {
    margin-bottom: 30px;

    @media screen and (max-width: 620px) {
      margin-bottom: 22px;
    }
  }

  .success-redirect-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #727272;

    a {
      color: #727272;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
</style>